import React from "react";
import { IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ExploreIcon from "./icons/ExploreIcon";
import MyPartiesIcon from "./icons/MyPartiesIcon";
import NotificationsIcon from "./icons/NotificationsIcon";
import ProfileIcon from "./icons/ProfileIcon";
import { theme } from "../services/Theme";
import { Box } from "@mui/material";

function NavigationButtons() {
  const location = useLocation();

  const buttonData = [
    { href: "/parties-gallery", Icon: ExploreIcon, label: "Explore" },
    { href: "/my-parties", Icon: MyPartiesIcon, label: "My parties" },
    { href: "/notifications", Icon: NotificationsIcon, label: "Notifications" },
    { href: "/profile", Icon: ProfileIcon, label: "Profile" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        justifyContent: "space-evenly",
      }}
    >
      {buttonData.map((button, index) => (
        <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
          <IconButton
            color={location.pathname === button.href ? "secondary" : "default"}
            aria-label={button.label}
            href={button.href}
            style={{
              borderRadius: "8px",
              backgroundColor:
                location.pathname === button.href
                  ? theme.palette.secondary.secondary
                  : "default",
            }}
          >
            <button.Icon
              strokeColor={
                location.pathname === button.href
                  ? theme.palette.secondary.main
                  : undefined
              }
            />
          </IconButton>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "16px",
              letterSpacing: "-0.20000000298023224px",
              textAlign: "center",
              color:
                location.pathname === button.href
                  ? theme.palette.secondary.main
                  : "inherit", // Use 'inherit' or another default color
            }}
          >
            {button.label}
          </div>
        </Box>
      ))}
    </Box>
  );
}

export default NavigationButtons;
