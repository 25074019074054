import React, { useState } from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import PageWrapper from '../../components/PageWrapper';
import InvitationsFilters from './InvitationsFilters';
import OrganizingInvitations from './InvitationTabs/OrganizingInvitations';
import Box from '@mui/material/Box';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { styled } from '@mui/material/styles';
import AttendingInvitation from './InvitationTabs/AttendingInvitation';
import AppliedInvitations from './InvitationTabs/AppliedInvitations';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '../../components/UI/Button';

const Invitations = () => {
  const [params, setParams] = useState({status: ['active', 'draft']});
  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setParams({status: ['active', 'draft']});
  };

  const handleFiltersClick = (name, value) => {
    if (!value.length) {
      const {[name]: unUsed, ...newParams} = params;
      setParams(newParams);
    } else {
      setParams(params => ({...params, [name]: value}));
    }
  };
  const handleClearFilters = () => {
    setParams({status: ['active', 'draft']});
  };

  return (
    <PageWrapper>
      <Stack direction={{xs: 'column', sm: 'row'}} alignItems={'flex-start'} justifyContent={'space-between'} sx={{width: '100%', mb: {xs: 2, sm: 1}}} flexWrap={'wrap'}>
        <Typography sx={{fontSize: 18, fontWeight: 400, mb:{xs: 2, sm: 0}}}>My Parties</Typography>
        <InvitationsFilters
          handleClear={handleClearFilters}
          handleFilter={handleFiltersClick}
          params={params}
          showCompleted
          sx={{width: {xs: '100%', sm: 'auto'}}}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-invitation"
            startIcon={<AddCircleIcon />}
            sx={{height: 'auto', py: 1, color: '#000'}}
          >
            PLAN A NEW PARTY
          </Button>
        </InvitationsFilters>
      </Stack>
      <Tabs
        variant={'scrollable'}
        value={tab}
        onChange={handleChangeTab}
        sx={{
          mt: 2,
          minHeight: 'auto',
          '& .MuiTabs-indicator': {backgroundColor: '#000'},
          '& .MuiTabs-flexContainer': {gap: 1.5, justifyContent: {xs: 'space-between', sm: 'flex-start'}},
        }}
      >
        <CustomTab disableRipple label="Organizing" icon={<AddBoxIcon/>}/>
        <CustomTab disableRipple label="Attending" icon={<EventNoteIcon/>}/>
        <CustomTab disableRipple label="Applied" icon={<EventAvailableIcon/>}/>
      </Tabs>
      <TabPanel value={tab} index={0}>
        <OrganizingInvitations params={params}/>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <AttendingInvitation params={params}/>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <AppliedInvitations params={params}/>
      </TabPanel>
    </PageWrapper>
  );
};

export default Invitations;

const TabPanel = ({ children, value, index, ...props }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        width: '100%',
        mt: 3,
        mb: {xs: 0, sm: 12, md: 6},
        pl: {sm: 2.5, md: 0},
      }}
      {...props}
    >
      {value === index && children}
    </Box>
  );
};

const CustomTab = styled(Tab)(() => ({
  color: 'rgb(113, 113, 113)',
  fontWeight: '600',
  fontSize: 12,
  padding: '0 0 8px',
  backgroundColor: 'transparent',
  alignItems: 'center',
  minHeight: 'auto',
  minWidth: 'auto',
  textTransform: 'inherit',
  transition: 'color 300ms ease',
  position: 'relative',
  //#DDDDDD
  '&.Mui-selected': {
    color: '#000',
    '& .MuiSvgIcon-root': {
      color: '#000',
    },
  },
  '&:hover': {
    color: '#000',
    '& .MuiSvgIcon-root': {
      color: '#000',
    },
  },
  '& .MuiSvgIcon-root': {
    color: 'rgb(113, 113, 113)',
    transition: 'color 300ms ease',

  }
}));
