import React from 'react';
import { Button } from '../../../components/UI/Button';
import { useAuthByGoogleMutation } from '../api';
import { useGoogleLogin } from '@react-oauth/google';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { isUserFillProfile } from '../../../util/isUserFillProfile';

const GoogleAuthButton = ({sx}) => {
	const [signUpByGoogle, {isLoading}] = useAuthByGoogleMutation();
	const history = useHistory();
	const location = useLocation();

	const login = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: (codeResponse) => signUpByGoogle(codeResponse).then(res => {
			if (res?.data?.user) {
				history.push(isUserFillProfile(res?.data?.user) ? '/parties-gallery' : '/complete-your-profile');
			}
		}),
		onError: (error) => console.log('Login Failed:', error),
	});

	return (
		<>
			<Button
				variant={'outlined'}
				fullWidth
				onClick={login}
				sx={{...sx}}
			>
				{location.pathname === '/join' ? 'Sign up' : 'Log in'}  with Google
			</Button>
			{isLoading && <Loader/>}
		</>
	)
};

export default GoogleAuthButton;
