import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Box,
  Accordion, AccordionSummary, AccordionDetails, Stack
} from '@mui/material';
import { FooterNav } from './Footer';
import { Button } from './UI/Button';
import logo from "../assets/party-club-logo-gold.svg";
import menuGold from "../assets/menu_gold.svg";
import settingsWhite from "../assets/settings_white.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Navbar = () => {
  const {user, notifications} = useSelector(state => state); //eslint-disable-line
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsPage, setIsSettingsPage] = useState(false); //eslint-disable-line

  const handleMenu = () => {
    setIsMenuOpen(state => !state);
  };

  return (
    <>
      <Drawer
        sx={{
          "& > div": {
            transition: "all 0.2s ease-in-out",
            borderRight: "unset !important",
            maxWidth: {xs: '100%', sm: '350px'},
            minWidth: {xs: 'auto', md: 350},
            width: "100%",
            color: "#0E1D31",
            backgroundColor: "unset !important",
            zIndex: 100,
            height: {xs: 'calc(100vh - 68px)', md: 'calc(100vh - 50px)'},
            top: {xs: 0, md: 20},
            padding: {xs: 0, md: '0 0 20px 20px'},
            mt: {xs: '68px', md: 0},
            transform: {
              xs: `${isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'}`,
              sm: `${isMenuOpen ? 'translateX(0)' : 'translateX(-350px)'}`,
              md: 'translateX(0)'
            },
          }
        }}
        variant="permanent"
        anchor="left"
      >
        {isSettingsPage ? (
          <Box sx={{height: "100%", width: "100%"}}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={0}
              sx={{height: "100%"}}
            >
              <Grid item xs={3} sm={3}>
                <List sx={{pt: "135px", "& a:first-child": {mb: "30px !important"}}}>
                  <ListItem
                    component={Link}
                    to="/parties-gallery"
                    sx={{
                      width: 16,
                      height: 16,
                      margin: "0px auto !important",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "14px",
                      borderRadius: "4px",
                    }}
                  >
                    <img src={menuGold} alt={'menu'} />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/settings/security-and-login/"
                    sx={{
                      width: "16px",
                      height: "16px",
                      margin: "0px auto !important",
                      backgroundColor: "#0E1D31",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "14px",
                      borderRadius: "4px",
                      "&:hover": {
                        backgroundColor: "#0E1D31",
                      },
                    }}
                  >
                    <img src={settingsWhite} alt={'settings'} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={9} sm={9} sx={{backgroundColor: "#373632", padding: "35px"}}>
                <List
                  sx={{
                    marginBottom: "65px !important",
                    padding: "0px",
                    display: {xs: 'none', md: 'flex'},
                    "& .MuiListItem-root": {
                      paddingTop: "0px !important",
                      paddingLeft: "0px !important",
                    },
                  }}
                >
                  <ListItem component={NavLink} to="/parties-gallery" sx={{'& > img': {width: '100%', maxWidth: 185}}}>
                    <img src={`${logo}`} alt={'logo'} />
                  </ListItem>
                </List>
                <List>
                  <ListItem
                    sx={NavItemSx}
                    component={NavLink}
                    to="/settings/security-and-login/"
                  >
                    <ListItemText>
                      SECURITY AND LOGIN
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    sx={NavItemSx}
                    component={NavLink}
                    to="/settings/notifications/"
                  >
                    <ListItemText>
                      NOTIFICATIONS
                      {user.notifications.length > 0 && (
                        <Box sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "5px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#0E1D31",
                          borderRadius: "50%",
                          fontSize: "14px",
                          color: "#373632",
                        }}>
                          {user.notifications.length}
                        </Box>
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        ) : <MenuMainInfo />}
      </Drawer>
      <Box sx={{position: "fixed", width: "100%", top: 0, zIndex: 99}}>
        <Toolbar
          sx={{
            backgroundColor: "#373632",
            height: "68px",
            display: {xs: 'flex', md: 'none'},
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative'
          }}
        >
          <IconButton edge="start" color="inherit" onClick={handleMenu} sx={{p: 0, m: 0}}>
            <MenuIcon
              sx={{
                width: "40px",
                height: "40px",
              }}
              color={'primary'}
            />
          </IconButton>
          <List sx={{margin: "auto", position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)', p: 0, m: 0}}>
            <ListItem component={Link} to="/parties-gallery" sx={{p: 0, '& > img': {width: '100%', maxWidth: 185}}}>
              <img src={logo} alt={'logo'}/>
            </ListItem>
          </List>
          <List sx={{
            "@media(max-width:550px)": {
              display: "none",
            },
          }}>
            <ListItem
              component={Link}
              to="/profile"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 0,
              }}
            >
              <ListItemText sx={{color: "#0E1D31", "& span": {fontSize: "14px", fontWeight: "600"}}}>
                {user.user.username}
              </ListItemText>
              <ListItemIcon sx={{
                width: "48px",
                height: "48px",
                minWidth: "unset !important",
                border: "1px solid #373632",
                borderRadius: "50%",
                marginRight: "0px !important",
                overflow: "hidden",
                display: "flex",
                "& img": {
                  width: "48px",
                  height: "48px",
                  objectFit: "cover",
                },
                "& svg": {
                  width: "48px",
                  height: "48px",
                },
              }}>
                {user?.user?.imgUrl?.length > 0 ? (
                  <img
                    src={user.user.imgUrl[0].src}
                    alt="profile"
                  />
                ) :  <AccountCircle/>}
              </ListItemIcon>
            </ListItem>
          </List>
        </Toolbar>
      </Box>
    </>
  );
};

export default Navbar;

export const MenuMainInfo = () => {
  const {user, notifications} = useSelector(state => state);
  const [expanded, setExpanded] = useState(false);
  const onChangeAccordion = () => {
    setExpanded(state => !state);
  }

  return (
    <Box sx={{height: "100%", width: "100%"}}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={0}
        sx={{ height: "100%"}}
      >
        <Grid item xs={12} sm={12} sx={{backgroundColor: "#373632", py: 4, px: {xs: 2, sm: 3, md: 4}}}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            spacing={0}
            sx={{height: "100%"}}
          >
            <List sx={{
              mb: "65px !important",
              padding: "0px",
              display: {xs: 'none', md: 'flex'},
              "& .MuiListItem-root": {
                pt: "0px !important",
                pl: "0px !important",
              },
            }}>
              <ListItem component={Link} to="/parties-gallery" sx={{'& > img': {width: '100%', maxWidth: 185}}}>
                <img src={`${logo}`} alt={'logo'}/>
              </ListItem>
            </List>
            <List disablePadding={true}>
              <ListItem
                component={NavLink}
                to="/profile"
                sx={{py: 1, px: 0}}
              >
                <ListItemIcon
                  sx={{
                    width: "48px",
                    height: "48px",
                    minWidth: "unset !important",
                    border: "1px solid #373632",
                    borderRadius: "50%",
                    marginRight: "10px !important",
                    overflow: "hidden",
                    display: "flex",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                    "& svg": {
                      width: "48px",
                      height: "48px",
                    },
                  }}
                >
                  {user?.user?.imgUrl?.length > 0 ? (
                    <img
                      src={user.user.imgUrl[0].src}
                      alt="profile"
                    />
                  ) :  <AccountCircle />}
                </ListItemIcon>
                <ListItemText sx={{"& span": {fontWeight: "400", fontSize: "14px", color: '#0E1D31'}}}>
                  {user.user.username}
                </ListItemText>
              </ListItem>
            </List>
            <List
              sx={{mt: 3}}
              disablePadding={true}
            >
              <ListItem
                sx={NavItemSx}
                component={NavLink}
                to="/parties-gallery"
              >
                <ListItemText>Party gallery</ListItemText>
              </ListItem>
              {user.user.role === "user" && (
                <ListItem
                  sx={NavItemSx}
                  component={NavLink}
                  to="/my-parties">
                  <ListItemText>MY PARTIES</ListItemText>
                </ListItem>
              )}
              {user.user.role === "user" && (
                <ListItem
                  sx={NavItemSx}
                  component={NavLink}
                  to="/my-applications"
                >
                  <ListItemText>MY APPLICATIONS</ListItemText>
                </ListItem>
              )}
              {user.user.role === "admin" && (
                <ListItem
                  sx={NavItemSx}
                  component={NavLink}
                  to="/user-management"
                >
                  <ListItemText>USER MANAGEMENT</ListItemText>
                </ListItem>
              )}
              <ListItem
                sx={NavItemSx}
                component={NavLink}
                to="/notifications"
              >
                <ListItemText>
                  NOTIFICATIONS
                  {notifications?.newNotificationsCount > 0 && (
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#0E1D31",
                      borderRadius: "50%",
                      fontSize: "14px",
                      color: "#373632",
                      ml: 0.5,
                    }}>
                      {notifications?.newNotificationsCount}
                    </Box>
                  )
                  }
                </ListItemText>
              </ListItem>
              <ListItem
                sx={NavItemSx}
                component={NavLink}
                to="/inbox"
              >
                <ListItemText>
                  INBOX
                </ListItemText>
              </ListItem>
              <ListItem
                sx={NavItemSx}
                component={NavLink}
                to="/settings"
              >
                <ListItemText>
                  SETTINGS
                </ListItemText>
              </ListItem>
            </List>
            <List sx={{display: {xs: 'flex', md: 'none', flexDirection: 'column'}}}>
              <Accordion
                disableGutters
                expanded={expanded} onChange={onChangeAccordion}
                sx={{
                  display: {xs: 'flex', md: 'none', flexDirection: 'column'},
                  background: 'transparent',
                  boxShadow: 'none',
                  '& .MuiButtonBase-root': {
                    padding: 0,
                    fontSize: 14,
                    color: '#0E1D31',
                    m: 0,
                    '& .MuiAccordionSummary-content': {
                      m: 0,
                      '&.Mui-expanded': {
                        m: 0
                      }
                    }
                  }
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#0E1D31'}} />}>
                  OTHER
                </AccordionSummary>
                <AccordionDetails sx={{p: '0 0 0 16px'}}>
                  <Stack>
                    <FooterNav sx={{color: '#0E1D31', mt: 3}} />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </List>
            <List sx={{mt: 'auto'}}>
              <ListItem sx={{px: 0}}>
                {user.user.role === "user" && (
                  <Button
                    sx={{
                      '&.MuiButton-contained': {
                        backgroundColor: '#0E1D31',
                        color: 'rgba(0, 0, 0, 0.87)',
                        height: 32,
                      }
                    }}
                    variant="contained"
                    color="action"
                    component={Link}
                    to="/create-invitation"
                    startIcon={<AddCircleIcon sx={{color: 'rgba(0, 0, 0, 0.87)',}} />}
                  >
                    CREATE PARTY
                  </Button>
                )}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

const NavItemSx = {
  padding: "8px 0px !important",
  '&.active ': {
    '& .MuiListItemText-root': {
      position: "relative",
      flex: "initial",
      "&::after": {
        width: "100% !important",
      },
    }
  },
  "&:hover": {
    backgroundColor: "unset !important",
  },
  "& span": {
    fontSize: "14px",
    display: "inline-flex",
  },
  "& .MuiListItemText-root": {
    position: "relative",
    flex: "initial",
    color: '#0E1D31'
  },
  "& .MuiListItemText-root:hover::after": {
    width: "100%",
  },
  "& .MuiListItemText-root::after": {
    content: '""',
    position: "absolute",
    bottom: "-5px",
    left: "0px",
    height: "1px",
    width: "0%",
    backgroundColor: "#0E1D31",
    transition: "0.3s all ease-in-out",
  },
}
