import { Box, Typography } from "@mui/material";
import InvitationCard from "../../../components/InvitationCard";
import NoItemsBox from "../../../components/NoItemsBox";
import React, { useState } from "react";
import { useInvitationsQuery } from "../api";

const AthensInvitations = () => {
  const [params] = useState({ locationFrom: "athens" });
  const { data, isLoading } = useInvitationsQuery({ params });

  return (
    <>
      <Typography variant="h5" component="div" sx={{ pb: "10px" }}>
        Top trending in Athens
      </Typography>
      {data?.data?.length > 0 && !isLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              overflowY: "auto",
              flexWrap: "no-wrap",
              gap: 2,
            }}
          >
            {data?.data?.map((invitation) => (
              <Box key={invitation.id}>
                <InvitationCard invitation={invitation} showIsApplied />
              </Box>
            ))}
          </Box>
        </>
      )}

      {!data?.data?.length && !isLoading && (
        <NoItemsBox
          title={
            params.locationFrom || params.startDate
              ? "No parties found ...yet"
              : "The gallery is empty at the moment."
          }
          description={
            params.locationFrom || params.startDate
              ? "Clearing filters or modifying their values can often unlock new possibilities and yield the results you're looking for. "
              : "Looks like the perfect moment to plan your next big event!"
          }
          buttonText={"Plan a New Party"}
          hideButton={params.locationFrom || params.startDate}
        />
      )}
    </>
  );
};

export default AthensInvitations;
