import { Portal, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationBlock, setStep } from '../slice';
import { Button } from '../../../components/UI/Button';
import {
  useAddEventPhotosMutation,
  useAddVideoMutation,
  useCreateInvitationMutation,
  useUpdateInvitationMutation
} from '../api';
import LeaveRouteGuard from '../../../components/LeaveRouteGuard';
import { dataURLtoBlob } from '../../../util/utils';
import { useHistory } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Loader from '../../../components/Loader';

const FormButtons = ({showNext = false, showPrev = false, showCreate = false, isNextDisabled = true, isCreateDisabled = false}) => {
  const {newInvitation, user} = useSelector(store => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const [create, {isSuccess: isCreateSuccess, error, isUninitialized, data: createdInvitation, isLoading: isCreateLoading}] = useCreateInvitationMutation();
  const [addEventPhotos, {isSuccess: isEventPhotosSuccess, error: eventPhotosError, isLoading: isEventPhotosLoading}] = useAddEventPhotosMutation();
  const [addVideo, {isSuccess: isVideoSuccess, error: videoError, isLoading: isVideoLoading}] = useAddVideoMutation();
  const [updateInvitation, {isSuccess: isUpdateSuccess, error: updateInvitationError, isLoading: isUpdateLoading, data: updatedInvitation}] = useUpdateInvitationMutation();
  const isError = error || eventPhotosError || updateInvitationError;

  useEffect(() => {
    if (isError) {
      dispatch(setNavigationBlock(true));
      enqueueSnackbar(error?.data.error || eventPhotosError || videoError || updateInvitationError?.data.error, {
        variant: 'error',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
        </IconButton>
      });
    }
  }, [error, eventPhotosError, updateInvitationError, videoError, dispatch, isError])


  useEffect(() => {
    if ((isCreateSuccess && !newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:')) && !isError) ||
      (isCreateSuccess &&  isEventPhotosSuccess) || (isCreateSuccess &&  isVideoSuccess && isEventPhotosSuccess) || isUpdateSuccess) {
      enqueueSnackbar(`${(isCreateSuccess && createdInvitation?.name) || (isUpdateSuccess && updatedInvitation?.name)} was created`, {
        variant: 'success',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
        </IconButton>
      });
      history.push('/my-parties');
    }
  }, [  //eslint-disable-line
    history,
    newInvitation?.draft?.eventPhotos,
    isCreateSuccess,
    isEventPhotosSuccess,
    isUpdateSuccess,
  ]);

  const onPrevStep = () => {
    dispatch(setStep(newInvitation.step - 1))
    window.scrollTo(0, 0);
  };

  const onNextStep = () => {
    dispatch(setStep(newInvitation.step + 1))
    window.scrollTo(0, 0);
  };

  const onCreateInvitation = () => {
    const {hostGallery, coHostsGallery, video, ...draft} = newInvitation?.draft;
    dispatch(setNavigationBlock(false));
    if (!isUninitialized && !error) {
      if (newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:'))) {
        const eventPhotos = newInvitation?.draft.eventPhotos.map(item => dataURLtoBlob(item));
        addEventPhotos({id: createdInvitation?.id, images: eventPhotos});
      }
      if (newInvitation?.draft?.video?.startsWith('data:')) {
        const video = dataURLtoBlob(newInvitation?.draft.videoitem);
        addVideo({id: createdInvitation?.id, video: video});
      }
      return;
    }
    if (!newInvitation?.draft?.id) {
      create({body: {...draft, status: 'active', host: user?.user?.id}}).then(res => {
        if (res.data) {
          if (newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:'))) {
            const eventPhotos = newInvitation?.draft.eventPhotos.map(item => dataURLtoBlob(item));
            addEventPhotos({id: res.data.id, images: eventPhotos});
          }
          if (newInvitation?.draft?.video?.startsWith('data:')) {
            const video = dataURLtoBlob(newInvitation?.draft.video);
            addVideo({id: res.data.id, video: video});
          }
        }
      });
    } else {
      updateInvitation({
        id: newInvitation?.draft?.id,
        body: {...draft, status: 'active', host: draft?.host?.id}
      })
    }
  };

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={2}
      sx={{
        position: {xs: 'static', md: 'fixed'},
        px: {xs: 0, md: 2},
        py: 2,
        zIndex: 1,
        right: 0,
        bottom: {xs: 0, md: 46},
        width: '100%',
        background: '#fff',
      }}
    >
      {showPrev && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onPrevStep}
          sx={{
            maxWidth: {xs: '100%', sm: '330px'},
            width: "100%",
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
        >
          PREVIOUS
        </Button>
      )}
      {showNext && (
        <Button
          variant="contained"
          color={'primary'}
          sx={{
            marginLeft: "auto",
            maxWidth: {xs: '100%', sm: '330px'},
            width: "100%",
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
          onClick={onNextStep}
          disabled={isNextDisabled}
        >
          NEXT
        </Button>
      )}
      {showCreate && (
        <Button
          disabled={isCreateDisabled}
          variant="contained"
          color="action"
          sx={{
            padding: "15px",
            maxWidth: {xs: '100%', sm: '330px'},
            width: "100%",
            backgroundColor: "#373632",
            "&.Mui-disabled": {
              opacity: 0.5,
            },
            "@media (max-width:700px)": {
              marginBottom: "20px",
            },
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
          onClick={onCreateInvitation}
        >
          Create my party
        </Button>
      )}
      <Portal>
        <LeaveRouteGuard
          // when={newInvitation.step > 0}
          shouldBlockNavigation={(location) => (
            !location.pathname.includes('/create-invitation') && newInvitation?.isNavigationBlocked
          )}
        />
      </Portal>
      {(isCreateLoading || isUpdateLoading || isEventPhotosLoading || isVideoLoading) && <Loader />}
    </Stack>
  );
};

export default FormButtons;
