import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import ExploreIcon from "./icons/ExploreIcon";
import MyPartiesIcon from "./icons/MyPartiesIcon";
import { IconButton } from "@material-ui/core";
import { theme } from "../services/Theme";
import React from "react";
import Profile from "./Profile";

const TopSidebarItems = () => {
  const location = useLocation();

  const buttonData = [
    { href: "/parties-gallery", Icon: ExploreIcon, label: "Explore" },
    { href: "/my-parties", Icon: MyPartiesIcon, label: "My parties" },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {buttonData.map((button, index) => (
        <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
          <IconButton
            color={location.pathname === button.href ? "secondary" : "default"}
            aria-label={button.label}
            href={button.href}
            style={{
              borderRadius: "8px",
              backgroundColor:
                location.pathname === button.href
                  ? theme.palette.secondary.secondary
                  : "default",
            }}
          >
            <button.Icon
              strokeColor={
                location.pathname === button.href
                  ? theme.palette.secondary.main
                  : undefined
              }
            />
          </IconButton>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "16px",
              letterSpacing: "-0.20000000298023224px",
              textAlign: "center",
              color:
                location.pathname === button.href
                  ? theme.palette.secondary.main
                  : "inherit", // Use 'inherit' or another default color
            }}
          >
            {button.label}
          </div>
        </Box>
      ))}
    </Box>
  );
};

const Sidebar = () => {
  const sidebarWidth = "93px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: sidebarWidth,
        backgroundColor: theme.palette.grey[100],
        py: "20px",
      }}
    >
      <div>
        <TopSidebarItems />
      </div>
      <Profile />
    </Box>
  );
};

export default Sidebar;
