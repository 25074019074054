import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import SaveDraftModal from '../features/invitations/create-invitation/SaveDraftModal';
import {
  useAddEventPhotosMutation,
  useAddVideoMutation,
  useCreateDraftMutation,
  useUpdateDraftMutation
} from '../features/invitations/api';
import { useDispatch, useSelector } from 'react-redux';
import { dataURLtoBlob } from '../util/utils';
import { mainApi, TAGS } from '../services/mainApi';
import { setError } from '../features/invitations/slice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';


const LeaveRouteGuard = ({when, shouldBlockNavigation}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {newInvitation, user} = useSelector(store => store);
  const [createDraft, {isSuccess, error}] = useCreateDraftMutation();
  const [updateDraft, {isSuccess: isDraftUpdateSuccess, error: updateInvitationError}] = useUpdateDraftMutation();
  const [addEventPhotos, {isSuccess: isEventPhotosSuccess, error: eventPhotosError}] = useAddEventPhotosMutation();
  const [addVideo, {isSuccess: isVideoSuccess, error: videoError}] = useAddVideoMutation();

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    if ((isSuccess && !newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:'))) ||
      (isSuccess && isEventPhotosSuccess && !newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:'))) ||
      ((isSuccess && isEventPhotosSuccess) || (isSuccess && isVideoSuccess) || (isSuccess && isEventPhotosSuccess && isVideoSuccess)
        || isDraftUpdateSuccess)) {
      setConfirmedNavigation(true);
      setModalVisible(false);
      enqueueSnackbar(`Draft ${isDraftUpdateSuccess ? 'Updated' : 'Saved'}! Your party invitation is under 'My Parties' for you to finish later.`, {
        variant: 'info',
        autoHideDuration: 5000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
        </IconButton>
      });
    }
  }, [isSuccess, newInvitation?.draft?.eventPhotos, isEventPhotosSuccess, isDraftUpdateSuccess, isVideoSuccess]);

  useEffect(() => {
    if (error || eventPhotosError || updateInvitationError || videoError) {
      setModalVisible(false);
      dispatch(setError({
        key: 'general',
        value: error?.data.error || eventPhotosError?.data.error || updateInvitationError?.data.error
      }))
    }
  }, [error, eventPhotosError, updateInvitationError, videoError, dispatch]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirm = () => {
    if (!newInvitation?.draft?.id) {
      const {eventPhotos, video, ...draft} = newInvitation?.draft;
      createDraft({body: {...draft, host: user?.user?.id}}).then(res => {
        if (res.data) {
          if (newInvitation?.draft?.eventPhotos?.some(item => item.startsWith('data:'))) {
            const eventPhotos = newInvitation?.draft.eventPhotos.map(item => dataURLtoBlob(item));
            addEventPhotos({id: res?.data?.id, images: eventPhotos});
          }
          if (newInvitation?.draft?.video?.startsWith('data:')) {
            const video = dataURLtoBlob(newInvitation?.draft.video);
            addVideo({id: res?.data?.id, video: video});
          }
        }
      });
    } else {
      const {eventPhotos, video, id, ...draft} = newInvitation?.draft;
      updateDraft({id, body: {...draft, host: draft?.host?.id}});
    }
  };

  const handleCancel = () => {
    setConfirmedNavigation(true);
    dispatch(mainApi.util.invalidateTags([TAGS.DRAFT]));
  }
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <SaveDraftModal open={modalVisible} onClose={closeModal} onConfirm={handleConfirm} onCancel={handleCancel} />
    </>
  );
};

export default LeaveRouteGuard;
