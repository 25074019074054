import React from "react";
import StaticPageWrapper from '../../components/StaticPageWrapper';
import { Typography, Container } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <StaticPageWrapper>
      <Container sx={{maxWidth: 830, pt: 3, '& > p': {color: 'rgba(0,0,0,.75)'}}} maxWidth={false}>
        <Typography sx={{textAlign: 'justify', fontSize: {xs: 24, sm: 32}}}>Privacy Policy</Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          1. Introduction
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The protection of personal data is important for us.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          This website (https://www.theparty.club/) is operated by the company under the corporate name “InviteCo Ltd” located in Nicosia, Cyprus (referred to as the “Company” or “us” or “we”) to offer to our website visitors (“you”, “your” or “users”). This Privacy Policy (the “Privacy Policy”) explains how the Company, collects, stores, uses, processes, and in general handles personal data when you visit, use, or get registered to its website or apps (hereinafter the “Site” or “PartyClub” or “Apps”) and/or using the Site and the services, features, content or applications offered by it (the “Services”). This Policy is designed to assist you in making informed decisions when using our website or interacting with us.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Individuals are recommended to read carefully this Policy before disclosing any personal data and/or filling in any electronic form posted on this Site. By visiting our website or by providing us with your personal data, you agree and consent to the collection, use, and disclosure of your personal information as outlined in this Policy.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          “Personal Data” means any information or set of information that relates to an individual such as name, address, email, telephone number, etc. which can be either direct or indirect and can be collected by or on behalf of the Company.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          2. A few words about the Company and the Site
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub is designed to bring like-minded people together. This website offers its users the opportunity to invite and be invited to parties.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          3. Personal Data We Collect
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We can’t help you develop meaningful connections without some information about you, such as basic profile details and basic info about yourself. We also collect information about your use of our services such as access logs, as well as information from third parties, like when you access our services through your social media account or when you upload information from your social media account to complete your profile. If you want additional info, we go into more detail below:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          Information you give us
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You choose to give us certain information when using our Services. This includes:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          When you create an account, you provide us with your email address. When you complete your profile, you can share with us additional information, such as details on your bio, interests, sector, gender, and other details about you, as well as content such as photos and videos.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          When you participate in surveys, focus groups, or market studies, you give us your insights into our products and services, responses to our questions, and testimonials.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you contact our customer care team, we collect the information you give us during the interaction.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you share with us information about other people (for example, if you use the contact details of a friend for a given feature), we process this information on your behalf to complete your request. We may also request the proven consent of the other person about the processing of his/her personal data.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Of course, we also process your chats with other members as well as the content you publish, as necessary for the operation of the Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          In connection with a financial transaction via the Site, we will collect your credit/debit card information or other financial and payment information through our third-party payment services provider. We will use this information solely in connection with the financial transaction and will not share this information with third parties, except to the extent necessary to complete the financial transaction or to comply with applicable law.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          Information we receive from others
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          In addition to the information, you may provide us directly, we receive information about you from others, including:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Members may provide information about you as they use our services, for instance as they interact with you or if they submit a report involving you. Any person sharing personal data of other people should have granted their consent proof which may be requested at any time during the use of Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Affiliates of PartyClub which is part of the Inverzio group. Inverzio considers the safety and security of members a top priority. If you were banned from another Inverzio service, your information can be shared with us to allow us to take necessary actions, including closing your account or preventing you from creating an account on our services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Other Partners. We may receive information about you from our partners where Inverzio ads are published on a partner’s service (in which case they may pass along details on a campaign’s success). Where legally allowed, we can also receive information about suspected or convicted bad actors from third parties as part of our efforts to ensure our members’ safety and security.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          Information collected when you use our Services
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          When you use our Services, this generates technical data about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Usage Information Using the services generates data about your activity on our services, for instance how you use them (e.g., when you logged in, features you’ve been using, actions taken, information shown to you, referring webpages address and ads that you interacted with) and your interactions with other members (e.g., members you connect and interact with, when you exchanged with them, number of messages you send and receive).
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Device information We collect information from and about the device(s) you use to access our services, including hardware and software information such as IP address, device ID and type, identifiers associated with cookies or other technologies that may uniquely identify a device or browser.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          4. Cookies and Other Similar Data Collection Technologies
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels, SDKs) to recognize you and/or your device(s). You may read our Cookie Policy for more information on why we use them and how you can better control their use.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Some web browsers (including Safari, Internet Explorer, Firefox, and Chrome) have a “Do Not Track” (“DNT”) feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many businesses, including PartyClub, do not currently respond to DNT signals.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          5. Purposes of processing personal data
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Company will process your personal data only for purposes permitted by applicable laws, which may vary depending on where you live, and where the Company operates, and on the terms outlined in this Policy.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Our primary purpose for collecting personal information from you is to provide and improve our Services, to administer your use of the Services, and to enable you to enjoy and navigate our Services. Fulfill our contract with you and provide you with our Services, such as:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Managing your information and accounts;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Providing access to certain areas, functionalities, and features of our Services;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Communicating with you about your account, activities on our Services, and policy changes;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Processing your financial information and other payment methods for products or Services purchased;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Processing applications and transactions.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Analyze and improve our Services under our legitimate interest, such as:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Measuring interest and engagement in our Services and short-term, transient use, such as contextual customization of ads;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Researching and developing products, services, marketing, or security procedures to improve their performance, resilience, reliability, or efficiency;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Improving, upgrading, or enhancing our Services
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Developing new products and Services;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Ensuring internal quality control;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Verifying your identity and preventing fraud;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Enforcing our terms and policies; and
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          -	Complying with our legal obligations, protecting your vital interest, or as may be required for the public good.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Managing our relationships/communications with individuals: for example, responding to questions and comments or inquiries, inviting individuals to the Company’s events, and making proposals for future service needs.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	For collaboration and research purposes: for example, to enable the Company to make more informed and objective decisions when identifying, and engaging with individuals interested in our Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Market research: processing data about individuals for lawful market research purposes. We collect data to help us improve our products and services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Direct marketing: to provide promotional material and engage in marketing and promotional activities with individuals in accordance with applicable laws.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Website functions: to ensure that content from our website is presented in the most effective manner for you and your device.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Legal or regulatory obligations and the directions of law enforcement agencies and court orders: to comply with our legal or regulatory requirements (reporting for the safety of information and product quality complaints) or to fulfill transparency requirements.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Sending you newsletters and other marketing material related to our project provided that you consent to these.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Ensuring that the content of our Site is presented in the most effective way for you and your device.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Company will process personal data for further purposes, where lawful to do so or when legally obliged to do so.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          For information on how we process personal information through profiling and automated decision-making, please see our FAQ.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          6.	Legal basis of processing
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          To process your information as described in this Privacy Policy, we rely on the following legal bases:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Provide our Services to you:</Typography> The reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections, we use your information to maintain your account and your profile and to provide our free and paid features to you and other members.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Legitimate interests:</Typography> We process your information based on our legitimate interests. For instance, we analyze users’ behavior on our services to continuously improve our offerings, we suggest offers we think might interest you and promote our services, we process information to help keep our members safe and we process data where necessary to enforce our rights, assist law enforcement and enable us to defend ourselves in the event of a legal action.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Comply with applicable laws and regulations:</Typography> We process your information where it is necessary for us to comply with applicable laws and regulations and evidence our compliance with applicable laws and regulations. For example, we retain traffic data and data about transactions in line with our accounting, tax, and other statutory data retention obligations and can respond to valid access requests from law enforcement. We also keep data evidencing consent members give us and decisions they may have taken to opt out of a given feature or processing.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Consent:</Typography> If you choose to provide us with information that may be considered “special” or “sensitive” in certain jurisdictions you’re consenting to our processing of that information in accordance with this Privacy Policy. From time to time, we may ask for your consent to collect specific information or use your information for certain specific reasons. In some cases, you may withdraw your consent by deleting your content (for instance where you entered information in your profile that may be considered “special” or “sensitive”). In any case, you may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          7. How We Share Information
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Since our goal is to help you make meaningful connections, the main sharing of members’ information is, of course, with other members. We also share some members’ information with service providers and partners who assist us in operating the services, with other Inverzio companies for specified reasons as laid out below and, in some cases, legal authorities. Read on for more details about how your information is shared with others below.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          With employees of our Company
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Access to your personal data is provided to the absolutely necessary staff of the Company, which has committed to maintaining confidentiality, and our partner companies or third-party service providers, who process your personal data as persons who execute the process on our behalf and in accordance with our orders.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	With other members
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          You share information with other members when you voluntarily disclose information on the Site (creating an invitation or when submitting an application, where you both fill in information and your public profile information is used). Please be careful with your information and make sure that the content you share is stuff that you’re comfortable with being visible.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          If someone submits a report involving you (such as a claim you violated our Terms of Use, we may communicate to the reporter actions, if any, we took because of their report.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	With our service providers and partners
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing, and security operations. We also share information with partners who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We follow a strict vetting process before engaging any service provider or working with any partner. Our service providers and partners must agree to strict confidentiality obligations.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	With our affiliates
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          PartyClub is part of the Inverzio group.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We share your information with affiliates for limited legitimate purposes as laid out below:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may share information with other Inverzio companies to make all Inverzio platforms safer and enable us to address (e.g., ban) bad actors found on one platform also on the others; for them to assist us in data processing operations, as service providers, upon our instructions, and on our behalf. Their assistance may include technical processing operations, such as data hosting and maintenance, customer care, marketing and targeted advertising, analytics, finance, and accounting assistance, improving our service, securing our data and systems, and fighting against spam, abuse, fraud, infringement, and other wrongdoings.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may also share information with other Inverzio companies for other legitimate business purposes including corporate audit, analysis, and consolidated reporting, where and as allowed under applicable law.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	For corporate transactions
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy, or other change of ownership or control.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	With law enforcement / when required by law
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government/law enforcement investigation, or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	To enforce legal rights
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and the legal rights of our members, business partners, or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	With your consent or at your request
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioral data, geolocation in de-identified form), as well as personal information in hashed, non-human readable form, under any of the above circumstances. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          8. Cross-Border Data Transfers
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Personal Data we collect for the purposes provided herein are stored in the EU. However, your personal data may be accessed by staff, suppliers, or service providers in, transferred to, and stored at, a location outside the EU or the EEA. Where the Company processes personal information in countries that may not provide the same level of data protection as in the EU/EEA or in your own country, where you are resident, the Company will implement reasonable and appropriate legal technical and organizational security measures to ensure the security of the processing and to protect your personal data from unauthorized access, use or disclosure.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          In particular, for residents of EEA – whenever we transfer your information outside of the EEA, we will take all reasonable steps to ensure that adequate safeguards are put in place to protect your information (unless we are permitted under applicable data protection law to make such transfers without additional formalities e.g., where the recipient country is considered an adequate destination). Such safeguards include the use of European Commission-approved standard contractual clauses as mentioned above. Where appropriate we may also ask for your explicit consent.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          9. Your Data Protection Rights
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We want you to be in control of your information, so we want to remind you of the following options and tools available to you:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Access / Update tools in the service. Tools and account settings can help you access, rectify, or remove information that you provided to us and that’s associated with your account directly within the service. If you have any questions about those tools and settings, please contact us for help.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Account closure. You can close your account by using the corresponding functionality directly on the service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Under applicable laws and subject to any legal restrictions, you may have the right to request us to:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Provide you with further details on the processing of your personal information;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Provide you access to your personal data that we hold about you;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ●	Update any inaccuracies in the personal information we hold that is demonstrated to be inaccurate or incomplete;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Delete any personal information that we no longer have a lawful basis to use;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Provide you or a third party, with a copy of your data in a digital format (data portability);
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Stop a particular processing when you withdraw your consent;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Object to any processing based on the legitimate interests or public interest to process information, unless our reasons for undertaking that processing outweigh any prejudice to your data protection rights; and
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Restrict certain aspects of the processing of your information.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          All data protection-related requests should be addressed to the Company at contact@inverzio.com
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          For your protection and the protection of all of our members, we may ask you to provide proof of identity before we can answer the above requests.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Keep in mind, that we may reject requests, including if we are unable to authenticate you, if the request is unlawful or invalid, or if it may infringe on trade secrets or intellectual property or the privacy or other rights of someone else. If you wish to receive information relating to another member, such as a copy of any messages you received from them through our service, the other member will have to contact us to provide their written consent before the information is released. We may also ask them to provide proof of identity before we can answer the request.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Also, we may not be able to accommodate certain requests to object to or restrict the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth and thus cannot ensure that you are 18 years of age or older.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to our Terms or this Privacy Policy).
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If we do not handle your request in a timely manner, or if you are not satisfied with our response to any exercise of these rights, you are entitled to lodge a complaint with the competent supervisory authority. Further information and contact details of the competent supervisory authorities can be found here.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          10. Security and data retention
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy, applicable data protection laws and regulations as well as international security standards. All data you provide to us is stored on secure servers and accessed and used subject to our security policies and standards. The Company has implemented reasonable physical, technical, and managerial controls and safeguards to keep your personal data protected from unauthorized access, disclosure, alteration, and destruction. Access to your personal data is limited to a restricted number of the Company’s employees whose duties reasonably require such information and third parties with whom the Company contracts to carry out business activities related to the provision of Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We keep your personal information only as long as we need it for legitimate business purposes and as permitted by applicable law. Data may be retained for a longer duration where applicable laws or regulations require or allow the Company to do so. When your data is no longer needed it will be either irreversibly anonymized (and the anonymized information may be retained) or securely destroyed. If you decide to stop using our Services, you can close your account. Note that we will close your account automatically if you are inactive for two years. After your account is closed, we will delete your personal information, as laid out below:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          To protect the safety and security of our members, we implement a safety retention window of three months following account closure, or one year following an account ban. During this period, we keep your information if it might be necessary to investigate unlawful or harmful conduct. The retention of information during this safety retention window is based on our legitimate interest as well as that of potential third-party victims.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Once the safety retention window elapses, we delete your data and only keep limited information for specified purposes, as permitted under applicable law.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Data may be retained for a longer duration where applicable laws or regulations require or allow the Company to do so. When your data is no longer needed it will be either irreversibly anonymized (and the anonymized information may be retained) or securely destroyed.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          11. Job Candidates, Contractors and Vendor Representatives
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We process the personal information of our job candidates, contractors, and vendor representatives, as part of our recruitment and talent management operations and our management of the services that contractors and vendors provide to us. If you are a job candidate, contractor, or vendor representative of PartyClub, certain relevant terms of this Privacy Policy apply to our processing of your personal information, including the sections of this Privacy Policy that discuss the entity that is responsible for the processing of your personal information, transfers of personal information, rights you may have under applicable law and how to contact us.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you are a job applicant, the personal information we process about you may vary depending on the job you seek but typically includes what you provide to us as part of your job application as well as professional qualifications, background, and reference information that recruiters or other third parties share with us. We use this information to support the recruitment process, which may lead to an employment contract. For contractors and vendor representatives, we may process identification information and work-related information, as necessary to manage our relationship with you and your employer, which is necessary for the performance of the services agreement, and to establish, exercise, or defend potential legal claims. We may share personal information with service providers that assist us with recruitment and technical data processing operations as well as with Inverzio companies (for instance if you have a business relationship with employees of an affiliate). We keep your personal information only as long as necessary for those purposes.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          12. Privacy Policy Changes
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Company reserves the right to amend this Policy from time to time to reflect technological advancements, legal and regulatory changes, and the Company’s business practices, subject to applicable laws. If the Company changes its privacy practices, an updated version of this Policy will reflect those changes by posting any revisions with the respective update of the effective date listed at the bottom of this Policy. We therefore encourage you to periodically visit this page to stay informed of how we are using your personal data.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          13. How to Contact Us
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you have questions about this Privacy Policy, please send us your request through the Contact Us section.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography sx={{fontWeight: 600}} component={'span'}>Last updated on:</Typography> 04/12/2023
        </Typography>
      </Container>
    </StaticPageWrapper>
  );
}
export default PrivacyPolicy;
