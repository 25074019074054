import { useSelector } from 'react-redux';
import { useHostInvitationsQuery } from '../api';
import { Grid, Typography } from '@mui/material';
import InvitationCard from '../../../components/InvitationCard';
import NoItemsBox from '../../../components/NoItemsBox';
import React from 'react';

const OrganizingInvitations = ({params}) => {
  const {user} = useSelector(store => store);
  const {data = {}, isLoading} = useHostInvitationsQuery({id: user?.user?.id, params}, {skip: !user?.user?.id});

  return (
    <>
      {data?.data?.length > 0 && !isLoading && (
        <Grid container spacing={3}>
          {data?.data?.map((invitation) => (
            <Grid item xs={12} sm={6} md={3} xl={3} key={invitation.id}>
              <InvitationCard invitation={invitation} showStatus showApplications />
            </Grid>
          ))}
        </Grid>
      )}
      {!data?.data?.length && !isLoading && (
        <NoItemsBox
          title={params.locationFrom || params.startDate ? 'No parties found ...yet' : 'The party hasn\'t started yet!'}
          description={params.locationFrom || params.startDate ? 'Clearing filters or modifying their values can often unlock new possibilities and yield the results you\'re looking for. ' :'Get the ball rolling and organize a party that everyone will remember.'}
          buttonText={'Plan a New Party'}
          hideButton={params.locationFrom || params.startDate}
        />
      )}
      {isLoading && (<Typography sx={{fontSize: 16}}>...Loading</Typography>)}
    </>
  );
};

export default OrganizingInvitations;
