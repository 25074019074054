import Parties_Header from "../../../assets/parties_header.jpeg";
import React from "react";
import { useMediaQuery } from "@mui/material";

export default function InvitationsHeader() {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <div
      style={{
        backgroundImage: `url(${Parties_Header})`,
        borderRadius: "20px",
        fontColor: "white",
        objectFit: "cover",
        width: "100%",
        height: mdUp ? "25dvh" : "25dvh",
        color: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: mdUp ? "-16rem" : "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: mdUp ? "center" : "start",
        padding: "32px",
      }}
    >
      <div
        style={{
          fontSize: mdUp ? "52px" : "36px",
          fontWeight: 650,
          lineHeight: mdUp ? "65.73px" : "45.5px",
          letterSpacing: "-0.20000000298023224px",
          textAlign: mdUp ? "center" : "left",
          width: mdUp ? "50%" : "75%",
        }}
      >
        Where party magic happens
      </div>
    </div>
  );
}
