import React from 'react';
import StaticPageWrapper from '../../components/StaticPageWrapper';
import { Container, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const TermsConditions = () => {
  return (
    <StaticPageWrapper>
      <Container sx={{maxWidth: 830, pt: 3, '& > p': {color: 'rgba(0,0,0,.75)'}}} maxWidth={false}>
        <Typography sx={{textAlign: 'justify', fontSize: {xs: 24, sm: 32}}}>Terms & Conditions</Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          These Terms and Conditions govern your use of our Site located at
          https://www.theparty.club/ (the “Site”) and the platform services provided by the
          company under the corporate name “InviteCo Ltd” (together “PartyClub” or “we” or
          “us”). Please read these Terms collectively with the Privacy Policy (together the
          “Terms”) fully and carefully before using the Site and the services, features,
          content, or applications offered by it (the “Services”). These Terms set forth the
          legally binding terms and conditions for your use of the Site and the Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You don't have to be a member to access and view the general information of the
          platform and other data and information made available to visitors of the Site (the
          “Visitors”). Members of PartyClub are considered those who have completed the
          sign-up process and created an account. Members and Visitors are collectively
          referred to as “Users” or “you”.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          1. Acceptance of Terms of Use
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Binding contract.</Typography> By creating a PartyClub
          account, whether through a mobile device, mobile application, or computer (collectively, the “Service”) you
          agree that you have read and agreed to be bound by these Terms and all other terms and conditions, operating
          rules, policies, and procedures that may be published from time to time through the Services by us or
          otherwise provided to you in connection with the Services, each of which is incorporated by reference into
          these Terms. If you do not accept and agree to be bound by all of the Terms, please do not use the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Applicability.</Typography> These Terms apply to all
          Users of the Services, including, without limitation, users who are contributors of content, information, and
          other materials or services, registered or otherwise.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Modification.</Typography> We reserve the right, in our
          sole and absolute discretion, to modify or replace any of these Terms, or change, suspend, or discontinue the
          Services (including without limitation, the availability of any feature, database, or content) at any time by
          posting a notice on the Site or by sending you notice through the Services, via email or by another
          appropriate means of electronic communication. We may also impose limits on certain features and services or
          restrict your access to parts or all of the Services without notice or liability. While we will timely provide
          notice of modifications, it is also your responsibility to check these Terms periodically for changes. Your
          continued use of the Services following notification of any changes to these Terms constitutes acceptance of
          those changes, which will apply to your use of the Services going forward. Your use of the Services is subject
          to the Terms in effect at the time of such use. If you don't agree to be bound by the modified Terms, then you
          are not permitted to use any of the Services anymore.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>2. Eligibility.</Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Eligibility.</Typography>
          You are not authorized to create an account or access or use the Service or systems it resides on unless all
          of the following are true:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● you can form a binding contract with PartyClub,
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● you will comply with these Terms and all applicable local, state, national and international laws, rules and
          regulations, and
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● you have never been convicted of a felony or indictable offense (or crime of similar severity), a sex crime,
          or any crime involving violence, and you are not required to register as a sex offender with any state,
          federal,
          or local sex offender registry.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400,}}>
          We may, in our sole discretion, refuse to offer the Services to any person or entity and change its
          eligibility criteria at any time. You are not eligible to use the Services if you violate any provision of
          these Terms as determined in our sole discretion.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          3. Registration
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          To use PartyClub, you may sign in using your email. For more information regarding the information we collect
          from you and how we use it, please consult our <Link component={RouterLink} to={'/privacy-policy'}>Privacy
          Policy</Link>.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you want to use certain features of the Services, we may require you to register for an account on the
          Services (the "Account”).
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You must provide accurate and complete information and keep your Account information updated. If you provide
          any information that is inaccurate, outdated, or incomplete, or PartyClub has reasonable grounds to suspect
          that such information is inaccurate, outdated, or incomplete, PartyClub may deny you to use the Services,
          areas requiring registration and/or suspend or terminate your Account, at its sole discretion.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You shall not: (i) select or use as a username a name of another person with the intent to impersonate that
          person; (ii) use as a username a name subject to any rights of a person other than you without appropriate
          authorization; or (iii) use, as a username, a name that is otherwise offensive, vulgar or obscene. You are
          solely responsible for the activity that occurs on your Account, and for keeping your Account password and
          login credentials secure. You may never use another person’s user Account or registration information for the
          Services without permission. You must notify us immediately of any change in your eligibility to use the
          Services (including any changes to or revocation of any licenses from state authorities), breach of security,
          or unauthorized use of your Account. You should never publish, distribute, or post login information for your
          Account. You shall have the ability to delete your Account, either directly or through a request made to one
          of our employees or affiliates.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You’ll have great fun on PartyClub, but if you feel the need to leave, you can delete your Account at any time
          by going to the ‘User Profile’ page when you are logged in, ‘Edit’ user profile, and clicking on the ‘Close
          account’. Your Account will be deleted immediately but it may take a little while for Your Content (defined
          below) to be completely removed from PartyClub. Your profile information will be treated by our Privacy
          Policy. If you delete your Account and try to create a new account within this period using the same
          credentials, we will re-activate your Account for you.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          4. Safety; Your Interactions with Other Members
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Though PartyClub strives to encourage a respectful member experience through features like instant messaging
          after a successful connection that allows members to communicate only after they have both indicated interest
          in one another, PartyClub is not responsible for the conduct of any member on or off of the Service. You agree
          to use caution in all interactions with other members, particularly if you decide to communicate off the
          Service or meet in person. You agree that you will not provide your financial information (for example, your
          credit card or bank account information), or wire or otherwise send money to other members.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT PARTYCLUB DOES NOT
          CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS.
          PARTYCLUB MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR COMPATIBILITY OF ANY MEMBERS.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          5. Content
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Definition
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          For purposes of these Terms, the term "Content" includes, without limitation, information, data, text,
          photographs, videos, audio clips, written posts and comments, software, scripts, graphics, works of authorship
          of any kind, interactive features and information or materials that are posted, generated, provided, or
          otherwise made accessible on or through the Services. For the present, “Content” also includes all User
          Content (as defined below).
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● PartyClub’s Content – Use Rights
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Services may contain Content specifically provided by us, our partners, or our users, and such Content is
          protected by copyrights, trademarks, service marks, patents, trade secrets, or other proprietary rights and
          laws. You shall abide by and maintain all copyright notices, information, and restrictions contained in any
          Content accessed through the Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and
          non-sublicensable license to access and use the Service. This license is for the sole purpose of letting you
          use and enjoy the Service’s benefits as intended by PartyClub and permitted by these Terms. Use, reproduction,
          modification, distribution, or storage of any Content (other than your User Content) for other than purposes
          of using the Services is expressly prohibited without prior written permission from us. You shall not sell,
          license, rent, or otherwise use or exploit any Content (other than your User Content) for commercial use or in
          any way that violates any third-party right.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          This license and any authorization to access the Service are automatically revoked if you do any of the
          following:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - act in an unlawful or unprofessional manner including being dishonest, abusive, or discriminatory
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - misrepresent your identity, your age, your current or previous positions, qualifications, or affiliations
          with a person or entity
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - disclose information that you do not have the consent to disclose
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - stalk or harass any other user of PartyClub
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - create or operate a pyramid scheme, fraud, or other similar practice
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - use the Service or any content contained in the Service for any commercial purposes without our written
          consent.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any
          copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content,
          or proprietary information accessible through the Service without PartyClub’s prior written consent.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - express or imply that any statements you make are endorsed by PartyClub.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - develop, support, or use software, devices, scripts, robots, other types of mobile code, or any other means
          or processes (including crawlers, browser plugins add-ons, or other technology) to scrape or otherwise
          exfiltrate from Bumble or its services, or otherwise copy profiles and other data from the services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - use the Service in any way that could interfere with, disrupt, or negatively affect the Service or the
          servers or networks connected to the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - upload viruses or other malicious code or otherwise compromise the security of the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - forge headers or otherwise manipulate identifiers to disguise the origin of any information transmitted to
          or through the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - "frame" or "mirror" any part of the Service without PartyClub’s prior written authorization.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - use meta tags or code or other devices containing any reference to PartyClub or the Service (or any
          trademark, trade name, service mark, logo, or slogan of PartyClub) to direct any person to any other Site for
          any purpose.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or otherwise disassemble
          any portion of the Service, or cause others to do so.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - use or develop any third-party applications that interact with the Service or other members' Content or
          information without our written consent.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - probe, scan, or test the vulnerability of our Service or any system or network.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - encourage or promote any activity that violates these Terms.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The names, logos, product and service names, designs, slogans, and other trademarks associated with the
          Services are ours and those of our licensors. You must not use any of the foregoing without our prior written
          permission. All other names, logos, product and service names, designs, slogans, and other trademarks used in
          connection with the Services are the trademarks of their respective owners.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub may investigate and take any available legal action in response to illegal or unauthorized uses of
          the Service, including termination of your account.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          Any software that we provide you may automatically download and install upgrades, updates, or other new
          features. You may be able to adjust these automatic downloads through your device's settings.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● User Content – Use Rights
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          All Content added, created, uploaded, submitted, distributed, or posted to the Site by users (the "User
          Content"), whether publicly posted or privately transmitted, is the sole responsibility of the person who
          originated such User Content. You represent that all User Content provided by you is accurate, complete,
          up-to-date, and in compliance with all applicable laws, rules, and regulations. You retain ownership of any
          User Content created and/or uploaded by you and you have all rights that are necessary to grant us the license
          rights in your User Content under these Terms. You acknowledge that all Content, including User Content,
          accessed by you using the Services is at your own risk and you will be solely responsible for any damage or
          loss to you or any third party resulting therefrom. We do not guarantee that any Content you access on or
          through the Services is or will continue to be accurate. You also represent and warrant that neither your User
          Content, nor your use and provision of your User Content to be made available through the Services will
          infringe, misappropriate, or violate a third party's intellectual property rights, or rights of publicity or
          privacy, or result in the violation of any applicable law or regulation.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You hereby grant to PartyClub a non-exclusive, transferable, sublicensable, worldwide, perpetual, royalty-free
          license to host, store, use, copy, display, reproduce, adapt, edit, publish, modify, and distribute your User
          Content in connection with operating and providing the Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You can remove your User Content by requesting its deletion. However, in certain instances, some of your User
          Content may not be completely removed and copies of your User Content may continue to exist on the PartyClub’s
          Services to the extent permitted by law. We are not responsible or liable for the removal or deletion of (or
          the failure to remove or delete) any of your User Content. You are responsible for constantly checking and
          validating the Content related to you.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Third Party’s Content
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We may display Content that is owned by a third party or licensed to us by a third party ("Third-Party
          Content"). PartyClub does not claim any ownership rights in the Third-Party Content. We provide the
          Third-Party Content only as a convenience and you shall not publish or distribute any of the Third-Party
          Content. Our provision of the Third-Party Content does not mean that we have endorsed the third parties or the
          Third-Party Content that they have provided. Your use of the Third-Party Content is at your own risk and
          PartyClub disclaims all liability relating to your interaction with the Third-Party Content or the applicable
          third parties. PartyClub may share personally identifiable information you provide to us via the Services and
          financial and other information we have about you with third parties so that the third parties may personalize
          the Third-Party Content that they provide to you according to the Privacy Policy. We will comply with
          applicable legal requirements, including requirements to obtain your consent before we share such information
          with third parties, in connection with how we share this information. If you see Content on the Service that
          violates these Terms, please report it via our contact form.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Availability of Content
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We do not guarantee that any Content will be made available on the Site or through the Services. We reserve
          the right to but do not have any obligation to remove, edit, block, modify, or otherwise manipulate any
          Content in our sole discretion, at any time, without notice to you and for any reason (including, but not
          limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content
          or if we are concerned that you may have violated these Terms), or for no reason at all.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Rules of Conduct
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          a. As a condition of use, you promise not to use the Services for any purpose that is prohibited by these
          Terms. You are responsible for all of your activity in connection with the Services. Violation of our rules
          may result in the termination and cancellation of your Account. You acknowledge and agree that we may
          terminate any Account at any time for any reason (including, but not limited to, our independent assessment or
          the receipt of claims or allegations from third parties or authorities).
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          b. By way of example, and not as a limitation, you shall not (and shall not permit any third party to) either
          (a) take any action or (b) upload, download, post, submit, or otherwise distribute or facilitate distribution
          of any Content on or through the Service, including without limitation any User Content, that:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          I. infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any other
          person or entity or violates any law or contractual duty;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          II. uses the Services for any unauthorized purpose, or in violation of any applicable law, including
          intellectual property laws;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          III. uses screen reader technology, algorithms, or any other automated technological means to interpret,
          analyze, research, or gain information about any User Content that is not yours;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          IV. you know is false, misleading, untruthful, or inaccurate, including, but not limited to, providing
          inaccurate contact or Account information;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          V. results in the creation or operation of multiple user accounts;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          VI. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of
          another's privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity,
          contains or depicts sexual activity, otherwise violates any law or right of any third party, or is otherwise
          inappropriate as determined by us in our sole discretion;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          VII. constitutes unauthorized or unsolicited advertising, junk or bulk email ("spamming");
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          VIII. contains software viruses or any other computer codes, files, content, or programs that are designed or
          intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or
          telecommunications equipment or to damage or obtain unauthorized access to any system, data, password or other
          information of ours or of any third party;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          IX. is designed or intended to obtain password, Account, or private information from any User;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          X. impersonates any person or entity, including any of our employees, representatives, or users;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          XI. includes anyone’s identification documents or sensitive financial information
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          XII. includes any confidential information, violates the rights (including, without limitation, the rights of
          publicity and privacy and rights under a contract) of others, or otherwise contains any material that could
          give rise to any civil or criminal liability under applicable laws or regulations, or that otherwise may be in
          conflict with these Term
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          XII. includes any confidential information, violates the rights (including, without limitation, the rights of
          publicity and privacy and rights under a contract) of others, or otherwise contains any material that could
          give rise to any civil or criminal liability under applicable laws or regulations, or that otherwise may be in
          conflict with these Term
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          XII. includes any confidential information, violates the rights (including, without limitation, the rights of
          publicity and privacy and rights under a contract) of others, or otherwise contains any material that could
          give rise to any civil or criminal liability under applicable laws or regulations, or that otherwise may be in
          conflict with these Term
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          c. You shall not: (i) take any action that imposes or may impose (as determined by us in our sole discretion)
          an unreasonable or disproportionately large load on our (or our third party providers’) infrastructure; (ii)
          interfere or attempt to interfere with the proper working of the Services or any activities conducted on the
          Services; (iii) bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or
          restrict access to the Services (or other accounts, computer systems or networks connected to the Services);
          (iv) run any form of auto-responder or "spam" on the Services; (v) use manual or automated software, devices,
          or other processes to “crawl” or “spider” any page of the Site without our express written permission; (vi)
          harvest or scrape any Content from the Services; or (vii) otherwise take any action in violation of our
          guidelines and policies (viii)use the Service for any purpose that is illegal or prohibited by this Agreement,
          (ix) use the Service for any harmful or nefarious purpose or (x) use the Service in order to damage PartyClub,
          (xi) spam, solicit money from, or defraud any Members, (xii), impersonate any person or entity or post any
          images of another person without his or her permission, (xiii) bully, "stalk", intimidate, assault, harass,
          mistreat or defame any person.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          d. You shall not (directly or indirectly): (i) decipher, decompile, disassemble, or otherwise attempt to
          derive any source code or underlying ideas or algorithms of any part of the Services (including without
          limitation any application), (ii) modify, translate, or otherwise create derivative works of any part of the
          Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive
          hereunder. You shall abide by all applicable local, state, national, and international laws and
          regulations.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          e. You shall not recruit, solicit, or encourage any other User to use third-party services or Sites that are
          competitive to ours;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          f. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe
          is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii)
          enforce these Terms, including investigation of potential violations hereof, (iii) detect, prevent, or
          otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect
          the rights, property or safety of us, our users and the public. </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          g. If for any reason the Service is not running as originally planned (e.g., if it becomes corrupted or does
          not allow the proper usage and processing of entries in accordance with the rules, or if infection by a
          computer virus, bugs, tampering, unauthorized intervention, actions by entrants, fraud, technical failures, or
          any other causes of any kind, in the sole opinion of PartyClub corrupts or affects the administration,
          security, fairness, integrity, or proper conduct of the Service), PartyClub reserves the right, in its sole
          discretion, to disqualify any individual implicated in or relating to the cause and/or to cancel, terminate,
          extend, modify or suspend the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          In consideration of PartyClub allowing you to use the Service, you agree that we, our affiliates, and our
          third-party partners may place advertising on the Service. By submitting suggestions or feedback to PartyClub
          regarding our Service, you agree that PartyClub may use and share such feedback for any purpose without
          compensating you.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          6. Payment processing
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Generally.</Typography> From time to time, PartyClub may
          offer products and services for purchase ("in-app purchases") through carrier billing, PartyClub direct
          billing, or other payment platforms authorized by PartyClub. If you choose to make a purchase, you will be
          prompted to confirm your purchase with the applicable payment provider, and your method of payment will be
          charged at the prices displayed to you for the service(s) you've selected as well as any sales or similar
          taxes that may be imposed on your payments, and you authorize PartyClub or the third party account, as
          applicable, to charge you.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Refunds.</Typography> Generally, all charges for
          purchases are nonrefundable, and there are no refunds or credits. We may make an exception in the following
          cases:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, ml: {xs: 1, sm: 2}, fontWeight: 400}}>
          1. If a Guest cancels their approved application and no replacement is found after the deadline of the
          invitation plus three days, the acceptance fee for that Guest is returned to the Host.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          We shall make such a refund using the same means of payment as used by you in the initial transaction. In any
          case, no fees will be charged to you as a result of the refund.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Pricing.</Typography> PartyClub operates a global
          business, and our pricing varies by several factors. We frequently offer promotional rates - which can vary
          based on region and more. We also regularly test new features and payment options.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          7. Notice and Procedure for Making Claims of Copyright Infringement
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you believe that your work has been copied and posted on the Service in a way that constitutes copyright
          infringement, please contact us through our Contact Us form
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you contact us regarding alleged copyright infringement, please be sure to include the following
          information:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
          interest;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● a description of the copyrighted work that you claim has been infringed;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● a description of where the material that you claim is infringing is located on the Service (and such
          description must be reasonably sufficient to enable us to find the alleged infringing material);
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● your contact information, including address, telephone number and email address, and the copyright owner’s
          identity;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● a written statement by you that you have a good faith belief that the disputed use is not authorized by the
          copyright owner, its agent, or the law;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● and a statement by you, made under penalty of perjury, that the above information in your notice is accurate
          and that you are the copyright owner or authorized to act on the copyright owner's behalf.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub will terminate the accounts of repeat infringers.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          8. Third Party Services
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Services may permit you to link to or otherwise access other Sites, services, or resources on your device
          and the Internet, and other Sites, services, or resources may contain links to or be accessed by the Services
          or the Site (including, without limitation, sites and services to synchronize video to music). These other
          resources are not under our control, and you acknowledge that we are not responsible or liable for the
          content, functions, accuracy, legality, appropriateness, or any other aspect of such Sites or resources. The
          inclusion of any such link or access does not imply our endorsement or any association between us and their
          operators. You further acknowledge and agree that we shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods, or services available on or through any such Site or resource.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Site may include Google Maps features and content which are subject to the then-current version of the
          Google Maps Terms of Use and Privacy Policy.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography component={'span'} sx={{fontWeight: 600}}>Location-Based Services.</Typography> We may offer
          features that are based on the location of users and which may report on the locations of those users as they
          use the Services (the "Location-Based Services"). You may partake in using these Location-Based Services
          solely at your discretion and may opt out of providing such information by turning off those features. Should
          you use Location-Based Services, you are consenting to our collection and dissemination of your location
          information through the Services. Under no circumstances shall we be liable for claims or damages therefrom
          arising out of your informed decision to disseminate your location information through the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          9. Risk Assumption and Precautions
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          YOUR USE OF OUR SERVICES, OUR CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES, IS AT YOUR DISCRETION
          AND RISK. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OUR SERVICES, OUR CONTENT, OR
          OTHER CONTENT AVAILABLE THROUGH OUR SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU OR YOUR
          PROPERTY (INCLUDING ANY COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH OUR SERVICES), OR THE LOSS OF
          DATA THAT MAY RESULT FROM THE USE OF OUR SERVICES, OUR CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR
          SERVICES.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR
          USE OF THE SERVICES, AND ANY CONTACT YOU HAVE WITH OTHER MEMBERS, WHETHER IN PERSON OR ONLINE, REMAINS WITH
          YOU.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          ADDITIONALLY, YOUR ACCEPTANCE OF AN INVITATION AND YOUR JOINING OF ANY OTHER MEMBER FOR HOLIDAYS IS AT YOUR
          DISCRETION AND RISK. YOU ASSUME ANY AND ALL RISK FOR ANY PROPERTY DAMAGE OR LOSS, OR BODILY, MENTAL, OR
          PERSONAL INJURY, INCLUDING DEATH, RELATING TO OR ARISING FROM CONNECTING WITH OTHER MEMBERS.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You also understand that PartyClub makes no guarantees, either express or implied, regarding your ultimate
          compatibility with individuals you meet through the Service or as to the conduct of such individuals.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          10. Disclaimers
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Services
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PARTYCLUB PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND TO THE EXTENT PERMITTED BY
          APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH
          RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN). PARTYCLUB DOES NOT REPRESENT OR WARRANT THAT
          (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE, (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE
          CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE WILL BE ACCURATE.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub cannot guarantee and does not promise any specific results from the use of the Site and/or the
          Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub is not responsible for any error, omission, interruption, deletion, defect, delay in operation or
          transmission, communications, line failure, theft or destruction, or unauthorized access to, or alteration of,
          user or Member communications.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub is not responsible for any problems or technical malfunction of any telephone network or lines,
          computer online systems, servers or providers, computer equipment, software, failure of email or players on
          account of technical problems or traffic congestion on the Internet or at any Site or combination thereof,
          including injury or damage to users and/or Members or any other person’s computer related to or resulting from
          participating or downloading materials in connection with the Site and/or in connection with the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Content
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PARTYCLUB TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER MEMBER OR THIRD PARTY POSTS, SENDS, OR
          RECEIVES THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
          ACCESSED AT YOUR DISCRETION AND RISK.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub is not responsible for any incorrect or inaccurate Content posted on the Site or in connection with
          the Service, whether caused by users of the Site, Members, or by any of the equipment or programming
          associated with or utilized in the Service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          This Site is only a venue—it acts as a venue for individuals to find compatible companionship for holidays.
          PartyClub is not required to screen or censor information posted on the Site, including but not limited to,
          the identity of any user and any user content. We are not involved in any actual communication between
          Members. As a result, we have no control over the quality, safety, or legality of the information or profiles
          or the truth or accuracy of such information. You agree that you are solely responsible for your interactions
          with other Members and that PartyClub is not liable in any way for Member communications. PartyClub reserves
          the right but has no obligation, to monitor communications on the Site between you and other Members, or any
          other correspondence or interaction that occurs outside the scope of the Site. The Site and Service is a tool
          for providing the initial contact between Members, and anything beyond that is not in our control and is
          carried out at the Members’ own risk. Members must use common sense about what information they reveal to
          others via email or any other means of communication. It is your responsibility to ensure your safety and to
          investigate Members and verify they are legitimate holiday partner seekers. There are many different frauds,
          schemes, and deceptions on the Internet, and we strongly caution you to be skeptical of any Member until you
          learn more about them and verify their background.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          In addition to the preceding information under these Terms, any advice or thoughts that may be posted on the
          Site or through the Service provided for under these Terms are for informational and entertainment purposes
          only and are not intended to replace, or substitute for, any professional, financial, medical, legal or other
          advice. The Company makes no representations or warranties and expressly disclaims any and all liability
          concerning any treatment, action by, or effect on any person following the information offered or provided
          within or through the Site. If You have specific concerns or a situation arises in which You require
          professional or medical advice, You should consult with an appropriately trained and qualified specialist and
          seek immediate attention from the proper professional.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          The Site may be used only for lawful purposes by individuals. As one of the conditions of your use of the Site
          and the Service, you represent, warrant, and agree that You will not use (or plan, encourage, or help others
          to use) the Site or the Service for any purpose or in any manner that is prohibited by these Terms or by
          applicable law. It is your responsibility to ensure that your use of the Site and the Service complies with
          these Terms and to seek prior written consent from PartyClub for any uses not permitted or not expressly
          specified herein. PartyClub specifically prohibits any use of the Site or the Service, and You hereby agree
          not to use the Site or the Service, for any of the following:
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Using the Site as an escort or prostitute or using the Service to promote, solicit, or engage clients for an
          escort or prostitution service, or to engage or facilitate human trafficking of any kind, including past
          escort activities or affiliation with an escort site or service.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Posting or sending material that exploits people under the age of 18, soliciting personal information from
          anyone under 18, failing to report knowledge of a person under the age of 18, or continuing to use the site to
          interact in any way with anyone you know or believe is under the age of 18. Any violation of these
          prohibitions will result in termination of your Membership and possible referral to law enforcement.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Posting or providing in any way any incomplete, false, misleading, or inaccurate Content about yourself
          and/or your profile.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Impersonating or otherwise misrepresenting an affiliation, connection, or association with any person or
          entity.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Soliciting, from other users, passwords, or personal identifying information for commercial or unlawful
          purposes, or for any other reason that would constitute a violation of these Terms.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Engaging in advertising to, or solicitation of, other Members to send money, or buy or sell any products or
          services. You may not transmit any chain letters or junk/spam emails to other Members. If You breach the terms
          of this subsection and send or post unsolicited bulk email, spam, or other unsolicited communications of any
          kind through the Service, you acknowledge that you will have caused substantial harm to PartyClub.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Initiating contact with Members’ sites by any other means without first obtaining explicit permission from
          them to do so via the Site;
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Posting advertisements or solicitations of employment, business, or pyramid schemes.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Using the Site or Service for activities that violate any law, statute, ordinance, or regulation.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Using the Site or Service to encourage, promote, facilitate, or instruct others to engage in illegal
          activity.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Engage in any activity that violates criminal laws of the jurisdiction in which the Member resides, visits,
          or contacts Members.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Bullying, stalking, intimidating, or otherwise harassing any other Members or Company employees or
          representatives.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Framing or mirroring any part of the Service or the Site, without the Company's prior written authorization.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 2, sm: 4}}}>
          - Using the Site for any competitive purpose, including copying, soliciting, competitive or market analysis,
          or any other use by a competitor.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 0.5, sm: 1}, fontWeight: 400, ml: {xs: 1, sm: 2}}}>
          ● Third-party content and conduct
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          WE MAKE NO WARRANTY AS TO THE IDENTITY, CHARACTER, OR CONDUCT OF ANY MEMBERS AND ASSUME NO RESPONSIBILITY FOR A MEMBER’S COMPLIANCE WITH ANY APPLICABLE LAWS AND REGULATIONS. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER MEMBERS WITH WHOM YOU COMMUNICATE OR INTERACT, AS A RESULT OF YOUR USE OF THE SERVICES - PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON. PARTYCLUB EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY MEMBER OR OTHER THIRD PARTY.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY CONTENT, OR SERVICE ADVERTISED, CREATED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED SITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS CONTENT, OR SERVICES, UNLESS TO THE EXTENT REQUIRED TO PROVIDE OUR SERVICES.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          PartyClub is not responsible for user or Member conduct of any kind, whether online or offline.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You agree to, and hereby do, release PartyClub and its successors from any claims, demands, losses, damages, rights, and actions of any kind, including personal injuries, death, and property damage, that either directly or indirectly arises from your interactions with or conduct of other Members.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          11. Limitation of Liability
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL PARTYCLUB, ITS AFFILIATES, EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON, THROUGH, OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF PARTYCLUB HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL PARTYCLUB’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF THE AMOUNT PAID, IF ANY, BY YOU TO PARTYCLUB FOR THE SERVICE
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          12. Indemnity by You
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          You shall indemnify, defend and hold harmless the Company, its affiliates and their respective officers, directors, employees and agents, from and against any and all claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with the following, to the extent that they have not be caused based on our willful misconduct or gross negligence: (i) your access to, use or misuse of the Services, Content (ii) your User Content; your violation of these Terms; (iv) your non-compliance with any applicable law; or (v) your breach of your representation and warranties hereunder, (vi) your breach of any contract or other agreement you enter into in relation to our Services or through our Site, (vi) personal injury, property damage or other claims or damages, (vii) your interactions or transactions with any other user/members; (viii) infringement by you, or any third party using your Account or identity in connection with the Services, of any intellectual property or other right of any person or entity; or (x) any payments due and payable by you to us or any third party in connection with the Services.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          13. Governing Law
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          These Terms shall be governed by and construed in accordance with the laws of Cyprus. You agree that any dispute arising from or relating to the subject matter of these Terms shall be governed by the exclusive jurisdiction of the courts of Nicosia, Cyprus.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 600}}>
          14. Entire Agreement; Other
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          These Terms, which include the Privacy Policy and the Cookies Policy and any terms disclosed and agreed to by you if you purchase additional features, products, or services we offer on the Service, contains the entire agreement between you and PartyClub regarding the use of the Service. If any provision of these Terms is held invalid, the remainder of these Terms shall continue in full force and effect. The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. You agree that your PartyClub account is non-transferable and all of your rights to your account and its Content terminate upon your death. No agency, partnership, joint venture, fiduciary, or other special relationship or employment is created as a result of these Terms and you may not make any representations on behalf of or bind PartyClub in any manner.
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          If you have any questions, complaints, or claims about these Terms or the Services, you may contact us through contact@partyclub.com
        </Typography>
        <Typography sx={{textAlign: 'justify', my: {xs: 1, sm: 2}, fontWeight: 400}}>
          <Typography sx={{fontWeight: 600}} component={'span'}>Effective Date of Terms:</Typography> 04/12/2023
        </Typography>
      </Container>
    </StaticPageWrapper>
  );
}

export default TermsConditions;
