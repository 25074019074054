import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  useScrollTrigger
} from '@mui/material';
import Label from '../../../components/UI/Label';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import DatePicker from '../../../components/UI/DatePicker';
import dayjs from 'dayjs';
import Input from '../../../components/UI/Input';
import CustomSwitch from '../../../components/UI/CustomSwitch';
import { Button } from '../../../components/UI/Button';
import DeleteConfirmModal from '../DeleteConfirmModal';
import PreviewInvitationModal from '../PreviewInvitationModal';
import PageWrapper from '../../../components/PageWrapper';
import React, { useEffect, useState } from 'react';
import {
  useAddEventPhotosMutation, useAddVideoMutation,
  useDeleteImageMutation,
  useDeleteInvitationMutation, useDeleteVideoMutation,
  useUpdateInvitationMutation
} from '../api';
import { useHistory, useParams } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ImageRow from '../ImageRow';
import NoItemsBox from '../../../components/NoItemsBox';
import PreviewButton from '../PreviewButton';
import Loader from '../../../components/Loader';
import CustomTimePicker from '../../../components/CustomTimePicker';
import Video from '../../../components/Video';

const EditEventInvitation = ({invitation, isLoading, error, window}) => {
  const [updateInvitation, {isSuccess: isUpdateSuccess, isLoading: isUpdateInvitationLoading, error: updatingError}] = useUpdateInvitationMutation();
  const [deleteInvitation, {isSuccess: isDeleteSuccess, isLoading: isDeletenInvitationLoading, error: deletingError}] = useDeleteInvitationMutation();
  const [uploadEventPhotos, {error: eventPhotosError, isLoading: isUploadImageLoading}] = useAddEventPhotosMutation();
  const [deleteImage, {isLoading: isDeleteImageLoading}] = useDeleteImageMutation();
  const [uploadVideo, {isLoading: isUploadVideoLoading}] = useAddVideoMutation();
  const [deleteVideo, {isLoading: isDeleteVideoLoading}] = useDeleteVideoMutation();
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const history = useHistory();
  const {id} = useParams();
  console.log(JSON.stringify(invitation) === JSON.stringify(form), form, invitation)

  useEffect(() => {
    if (invitation && !form) {
      setForm(invitation);
    } else if (invitation && form) {
      setForm(state => ({...state, eventPhotos: invitation?.eventPhotos, video: invitation?.video}))
    }
  }, [invitation]); //eslint-disable-line

  useEffect(() => {
    if (isUpdateSuccess || isDeleteSuccess) {
      history.push('/my-parties');
    }
  }, [isUpdateSuccess, isDeleteSuccess, history]);

  const handleDateChange = (e) => (key) => {
    setForm(state => ({...state, [key]: dayjs(e?.$d).isValid() ? dayjs(e?.$d || null).format() : null}));
  };

  const handleChange = ({target: {name, value, type}}) => {
    setErrors(state => ({...state, [name]: null}));
    if (type === 'number') {
      setForm(state => ({...state, [name]: value < 0 ? Math.abs(value) : value}))
      if (name === 'numberOfCoHosts' && +value > 0 && !form?.genderOfCoHosts) {
        setErrors(state => ({...state, genderOfCoHosts: 'Please add coHosts gender'}));
      } else {
        setErrors(state => ({...state, genderOfCoHosts: null}));
      }
      if (name === 'roomsTaken' && +form?.totalRooms < +value) {
        setForm(state => ({...state, [name]: value < 0 ? Math.abs(value) : form?.totalRooms}))
        return;
      }
      if (name === 'totalRooms' && +form?.roomsTaken > +value) {
        setForm(state => ({...state, roomsTaken: value < 0 ? 0 : value}))
      }
      return;
    }
    setForm(state => ({...state, [name]: value}));
  };

  const handleLocation = (key) => (value) => {
    setErrors({});
    setForm(state => ({...state, [key]: value}));
    if (!value?.city || !value?.country) {
      setErrors(state => ({...state, [key]: 'Please provide city and country'}))
    }
  };

  const handleSwitch = ({target: {name, checked}}) => {
    setForm(state => ({...state, requiredFields: {...state?.requiredFields, [name]: checked }}));
  }

  const handlePlusOneSwitch = ({target: {name, checked}}) => {
    setForm(state => ({...state, [name]: checked}));
  }

  const handleImage = (name) => (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    uploadEventPhotos({id: invitation?.id, images: [e?.target?.files[0]]})
    e.target.value = '';
  };

  const handleDeleteImage = () => (item) => {
    deleteImage({id: invitation?.id, body: {filename: item}});
  };

  const handleVideo = (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    uploadVideo({id: invitation?.id, video: e?.target?.files[0]})
    e.target.value = '';
  };

  const handleDeleteVideo = () => {
    deleteVideo({id: invitation?.id, body: {filename: form?.video}});
  };

  const handleUpdate = () => {
    const {acceptedApplications, alias, coHostsGallery, deleted, hostGallery,
      status, totalApplications, updatedAt, video, ...rest} = form;
    updateInvitation({
      id: rest?.id,
      body: {...rest, host: rest?.host?.id, travelPackage: rest?.travelPackage?.id}
    }).then(res => {
      if (res?.data) {
        enqueueSnackbar(`${res?.data?.name} was updated`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
      }
    })
  };

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const handleDelete = () => {
    const name = invitation?.name;
    deleteInvitation({id}).then(res => {
      if (res.data) {
        enqueueSnackbar(`${name} was deleted`, {
          variant: 'error',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  return (
    <PageWrapper>
      {error && !isLoading && <NoItemsBox />}
      {invitation?.deleted && !isLoading && <Typography>Party was deleted</Typography>}
      {!error && !isLoading && !invitation?.deleted && (
        <>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <Box sx={{width: {xs: '100%', sm: '50%'}}}>
              <Typography sx={{fontWeight: 600, fontSize: 20, mb: 2}}>Edit party invitation</Typography>
              <Label>Party name</Label>
              <Input
                name="name"
                placeholder=""
                fullWidth
                value={form?.name || ''}
                onChange={handleChange}
                sx={{mb: 3}}
              />
              <Label>Location</Label>
              <Typography sx={{fontSize: 12, fontWeight: 200}}>Enter a general area, like 'Psychiko'</Typography>
              <GoogleAutocomplete
                value={form?.locationFrom || null}
                handleChange={handleLocation('locationFrom')}
                error={errors.locationFrom}
              />
              <Label>Precise address</Label>
              <Typography sx={{fontSize: 12, fontWeight: 200}}>This will be shared only when you accept a guest, ensuring privacy.</Typography>
              <Input
                onChange={handleChange}
                value={form?.preciseLocation || ''}
                sx={{mb: 4}}
                name={'preciseLocation'}
                fullWidth
              />
              <Label>Date</Label>
              <DatePicker
                name={'startDate'}
                value={form?.startDate || null}
                onChange={(e) => handleDateChange(e)('startDate')}
                minDate={dayjs().add(1, "day")}
                maxDate={
                  form?.endDate
                    ? dayjs(form?.endDate).add(-1, "day")
                    : dayjs().add(1, "year")
                }
                sx={{mb: 4}}
              />
              <Grid container spacing={4} sx={{mb: 4}}>
                <Grid item xs={12} md={6}>
                  <Label>Start time</Label>
                  <CustomTimePicker
                    value={dayjs(form?.startTime)}
                    onChange={(e) => handleDateChange(e)('startTime')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label>End time •<em>Optional</em></Label>
                  <CustomTimePicker
                    value={form?.endTime ? dayjs(form?.endTime) : null}
                    onChange={(e) => handleDateChange(e)('endTime')}
                  />
                </Grid>
              </Grid>
              <Label>Party information</Label>
              <Input
                fullWidth
                name="additionalInfo"
                placeholder={'Enter details about your party here (e.g., theme, dress code, type of music)'}
                multiline
                maxRows={5}
                value={form?.additionalInfo}
                onChange={handleChange}
                sx={{mb: 4}}
              />
              {invitation?.type === 'coHost' && (
                <>
                  <Label>Max capacity</Label>
                  <Input
                    name={'totalRooms'}
                    type={'number'}
                    value={form?.totalRooms || ''}
                    inputProps={{inputMode: 'numeric', min: 1}}
                    onChange={handleChange}
                    fullWidth
                    sx={{mb: 3}}
                  />
                  <Label>Available capacity</Label>
                  <Input
                    name={'roomsTaken'}
                    type={'number'}
                    inputProps={{inputMode: 'numeric', min: 0, max: form?.totalRooms}}
                    value={form?.roomsTaken || ''}
                    onChange={handleChange}
                    disabled={!form?.totalRooms}
                    fullWidth
                    sx={{mb: 3}}
                  />
                  <Label>Event cost</Label>
                  <Input
                    name={'totalCost'}
                    value={form?.totalCost || ''}
                    type={'number'}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onChange={handleChange}
                    sx={{mb: 3}}
                  />
                  <Label>Number of coHosts</Label>
                  <Input
                    sx={{mb: 4}}
                    name="numberOfCoHosts"
                    type="number"
                    value={form?.numberOfCoHosts || ''}
                    onChange={handleChange}
                    inputProps={{inputMode: 'numeric', min: invitation?.type === 'coHost' ? 1 : 0}}
                    fullWidth
                  />
                </>
              )}

              {invitation?.type === 'guest' && (
                <>
                  <Label>Total Guest Number •<em>Optional</em></Label>
                  <Input
                    name="numberOfGuest"
                    placeholder="Enter the expected number of guests"
                    type="number"
                    value={form?.numberOfGuest || ''}
                    onChange={handleChange}
                    sx={{mb: 4}}
                    inputProps={{inputMode: 'numeric', min: 1}}
                    helperText={errors?.numberOfGuest}
                    error={!!errors?.numberOfGuest}
                    fullWidth
                  />
                </>
              )}
              <Label>Plus One Policy</Label>
              <Typography sx={{fontSize: 12, fontWeight: 200}}>Are guests allowed to bring a plus one?</Typography>
              <FormControlLabel
                sx={{mb: 3}}
                control={
                  <CustomSwitch
                    name={'hasPlusOne'}
                    checked={form?.hasPlusOne || false}
                    onChange={handlePlusOneSwitch}
                  />
                }
                label={
                  <Typography sx={{color: '#373632', fontSize: 14, fontWeight: 400,}}>
                    {form?.hasPlusOne ? 'Yes' : 'No'}
                  </Typography>
                }
              />
              <Label sx={{mb: 2}}>Upload up to 5 photos to showcase your party</Label>
              <ImageRow
                images={form?.eventPhotos}
                error={eventPhotosError?.data?.error}
                onChange={handleImage('eventPhotos')}
                onDelete={handleDeleteImage('eventPhotos')}
                fiveImages
              />
              <Label sx={{mb: 2}}>Upload a video to showcase your party •<em>Optional</em></Label>
              <Video video={form?.video} onChange={handleVideo} onDelete={handleDeleteVideo} sx={{mb: 3}} />
              <Label>Deadline for application</Label>
              <DatePicker
                sx={{mb: 3}}
                value={form?.deadlineOfApplications || null}
                maxDate={dayjs(form?.startDate).add(-1, "day")}
                minDate={dayjs().add(1, "day")}
                onChange={(e) => handleDateChange(e)('deadlineOfApplications')}
              />
              {invitation?.type === 'guest' && (
                <>
                  <Label>Guest Introduction Video</Label>
                  <Typography sx={{fontSize: 12, fontWeight: 200}}>Choose whether guests need to upload a short video introducing themselves</Typography>
                  <FormControlLabel
                    sx={{mb: 3, width: '100%'}}
                    control={
                      <CustomSwitch
                        name={'video'}
                        checked={form?.requiredFields?.video || false}
                        onChange={handleSwitch}
                      />
                    }
                    label={
                      <Typography sx={{color: '#373632', fontSize: 14, fontWeight: 400,}}>
                        {form?.requiredFields?.video ? 'Yes' : 'No'}
                      </Typography>
                    }
                  />
                </>
              )}
              <Button
                sx={{maxWidth: {xs: 300, md: 400}}}
                variant={'outlined'}
                color={'error'}
                fullWidth
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete invitation
              </Button>
            </Box>
            <Box sx={{
              position: {xs: 'static', sm: 'fixed'},
              pb: {xs: 0, sm: 2},
              pr: {xs: 0, sm: 2.5},
              top: {xs: 50, sm: 75, md: 90},
              right: 0,
              transform: {xs: 'none', sm: trigger ? 'translateY(-75px)' : 'translateY(0)'} ,
              width: {xs: '100%', sm: '45%', md: '25%'},
              height: {xs: 'auto', sm: 'calc(100vh - 70px)', md: 'calc(100vh - 130px)'},
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {xs: 'center', sm: 'space-between'},
              transition: 'all 200ms linear 0ms'
            }}>
              <Box>
                <Typography sx={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#373632",
                  fontWeight: 700,
                  mb: 1,
                }}>
                  {form?.name}
                </Typography>
                <Typography sx={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#373632",
                  mb: 1.2,
                }}>
                  {dayjs(form?.startDate).format("DD MMM YYYY")}{" "}
                  -{" "}
                  {dayjs(form?.endDate).format("DD MMM YYYY")}
                </Typography>
                <Typography sx={{fontSize: 14, textTransform: 'uppercase', mb: 3, display: 'flex', alignItems: 'center'}}>
                  STATUS: <Typography component='span' sx={{fontWeight: 600, ml: 0.25}}> {invitation.status}</Typography>
                </Typography>
                <PreviewButton onClick={() => setOpenPreview(true)} />
              </Box>
              <Box>
                {(updatingError || deletingError) && (
                  <Typography color={'error'} sx={{mt: 2}}>{updatingError?.data?.error || deletingError?.data?.error}</Typography>
                )}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  sx={{maxWidth: {xs: 300, md: 400}, mt: 2}}
                  fullWidth
                  disabled={JSON.stringify(invitation) === JSON.stringify(form)}
                  onClick={handleUpdate}
                >
                  Update invitation
                </Button>
              </Box>
            </Box>
          </Stack>
          <DeleteConfirmModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onConfirm={handleDelete}
          />
          <PreviewInvitationModal
            open={openPreview}
            onClose={() => setOpenPreview(false)}
            invitation={form}
          />
        </>
      )}
      {(isUpdateInvitationLoading || isDeletenInvitationLoading || isDeleteImageLoading || isUploadImageLoading || isDeleteVideoLoading || isUploadVideoLoading) && <Loader />}
    </PageWrapper>
  );
};

export default EditEventInvitation;
