import React, { Component } from "react";
import PropTypes from "prop-types";
import slide1_guests from "./../../../assets/onboarding/slide1_guests.png";
import slide2_guests from "./../../../assets/onboarding/slide2_guests.png";
import slide3_guests from "./../../../assets/onboarding/slide3_guests_new.png";
import slide4_guests from "./../../../assets/onboarding/slide4_guests_new.png";
import slide5_guests from "./../../../assets/onboarding/slide5_guests_new.png";
import slide1_hosts from "./../../../assets/onboarding/slide1_hosts.png";
import slide2_hosts from "./../../../assets/onboarding/slide2_hosts.png";
import slide3_hosts from "./../../../assets/onboarding/slide3_hosts.png";
import slide4_hosts from "./../../../assets/onboarding/slide4_hosts.png";
import slide5_hosts from "./../../../assets/onboarding/slide5_hosts_new.png";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

const GUEST_SLIDES = [
  {
    title: 'Craft Your Profile',
    subtitle: 'LYour user profile is where your journey in Invite.Club begins. Fill out your details, share a bit about your travel tastes, and add a friendly photo. This is your chance to attract like-minded CoHosts and Guests for your next adventure.',
    img: slide1_guests
  },
  {
    title: 'Plan Your Next Adventure',
    subtitle: 'Looking to join a trip as a Guest? Take a scroll through our Invitations Gallery. It\'s filled with a variety of intriguing trip invites. Don\'t hesitate, apply! These invitations are specially designed for adventurous Guests like you.',
    img: slide2_guests
  },
  {
    title: 'Catch Your Dream Trip',
    subtitle: 'Spotted a trip you\'re totally stoked about? Just tap that "Apply to Invitation" button and fill in the details. Your next awesome journey is only a click away!',
    img: slide3_guests
  },
  {
    title: 'Your Applications at a Glance',
    subtitle: 'Curious about the status of your applications? Simply head over to "My Applications" to view all of them in one place. From there, you can easily edit or withdraw any submitted applications.',
    img: slide4_guests
  },
  {
    title: 'Prepare for Your Journey',
    subtitle: 'Once you\'ve been invited, rest assured that Invite.Club\'s trusted travel agency will handle all your travel arrangements. As the trip approaches, you\'ll be connected with your Host via your inbox, ensuring seamless communication two days prior to your adventure.',
    img: slide5_guests
  },
];

const HOST_SLIDES = [
  {
    title: 'Craft Your Profile',
    subtitle: 'Your user profile is where your journey in Invite.Club begins. Fill out your details, share a bit about your travel tastes, and add a friendly photo. This is your chance to attract like-minded CoHosts and Guests for your next adventure.',
    img: slide1_hosts
  },
  {
    title: 'Discover Your Next Adventure',
    subtitle: 'Seeking inspiration for your holiday or looking to join an exciting trip as a CoHost? Explore our Invitation Gallery, where you can browse through a myriad of trip invitations. Feel free to apply to any journey that sparks your interest, specifically tailored for our CoHosts.',
    img: slide2_hosts
  },
  {
    title: 'Kickstart Your Journey',
    subtitle: 'Want to invite some Guests to join your adventure or keen to find CoHosts to share your trip expenses? Simply fill out the necessary details and publish your Invitation. Your epic adventure is just a few clicks away!',
    img: slide3_hosts
  },
  {
    title: 'Choose Your Crew',
    subtitle: 'Are you all set to select your CoHosts or Guests? Head to "My Invitations", tap the "Applications" button, and peruse through Invite.Club members who\'ve applied to your Invitation. The perfect company for your epic adventure is just a click away!"',
    img: slide4_hosts
  },
  {
    title: 'Finalize Your Travel Details',
    subtitle: 'Eager to connect with your accepted CoHosts and Guests? Navigate to your inbox to discuss and finalize trip details. Rest assured, Invite.Club\'s dedicated travel agency will handle all the travel arrangements. Your seamless journey is just a conversation away.',
    img: slide5_hosts
  },
];

const styles = {
  waitingListWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
  },

  onboardingBody: {
    margin: "0px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: " 100%",
    marginBottom: "20px",
    height: "100%",
  },
  waitingListFormInner: {
    width: "100%",
  },
  onboardingButtonsWrapper: {
    width: " 100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: " flex-start",
    marginTop: "auto",
    "@media (max-width:680px)": {
      flexDirection: "column",
    },
  },
  onboardingSteps: {
    margin: "0px auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: " flex-start",
  },
  counterProgress: {
    position: "relative",
  },
  onboardingButton: {
    margin: "0px auto 20px auto",
    padding: "15px",
    width: "100%",
    maxWidth: "330px",
    boxShadow: "unset !important",
    "& .MuiButton-label": {
      lineHeight: "18px",
      fontWeight: "500",
    },
  },
  skipButton: {
    margin: "0px auto 20px auto",
    padding: "15px",
    width: "100%",
    maxWidth: "330px",
    boxShadow: "unset !important",
    "& .MuiButton-label": {
      lineHeight: "18px",
      fontWeight: "500",
    },
  },
  onboardingBackButton: {
    margin: "0px auto 0px auto",
    color: "#0E1D31 !important",
    padding: "15px",
    width: "100%",
    maxWidth: "330px",
    boxShadow: "unset !important",
    "& .MuiButton-label": {
      lineHeight: "18px",
      fontWeight: "500",
    },
    "@media(max-width:680px)": {
      marginBottom: "20px",
    },
  },
  onboardingTitle: {
    textAlign: "center",
    fontSize: "24px",
    lineHeight: "29px",
    marginBottom: "15px",
  },
  onboardingSubtitle: {
    textAlign: "center",
    maxWidth: "650px",
    margin: "auto",
    display: "block",
    fontFamily: "Roboto !important",
    fontSize: "16px",
    lineHeight: "24px",
  },
  img: {
    display: "block",
    maxWidth: "100%",
    width: "100%",
    height: "50vh",
    objectFit: "contain",
    margin: "20px 0px",
  },

  submitButton: {
    backgroundColor: "#0E1D31 !important",
    boxShadow: "unset !important",
  },
  prevButton: {
    border: "2px solid #0E1D31 !important",
    color: "#0E1D31 !important",
    boxShadow: "unset !important",
  },
  onboardingStepsPagination: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " center",
    marginBottom: "30px",
  },
  onboardingStepsPaginationSingle: {
    width: "20px",
    height: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    border: "1px solid #fff",
  },
  onboardingStepsPaginationSingleInner: {
    display: "block",
    width: "8px",
    height: "8px",
    backgroundColor: "#E0D1BC",
    borderRadius: "50%",
  },
  onboardingStepsPaginationSingleActive: {
    border: "1px solid #E0D1BC",
  },
};

class OnBoardingSlider extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
    };
  }

  nextStep = () => {
    if (this.state.step < 5) {
      this.setState({
        step: this.state.step + 1,
      });
    } else {
      this.props.history.push('/complete-your-profile')
    }
  };

  prevStep = () => {
    this.setState({
      step: this.state.step - 1,
    });
  };

  render() {
    const { classes } = this.props;
    const { step } = this.state;

    const formSteps = () => {
      const userRole = this.props?.user?.user?.role === "host" ? HOST_SLIDES :
                       this.props?.user?.user?.role === "guest" ? GUEST_SLIDES : null;
      if (userRole) {
        return (
          <div className={classes.waitingListFormInner}>
            <h1 className={classes.onboardingTitle}>
              {userRole[step - 1]?.title}
            </h1>
            <span className={classes.onboardingSubtitle}>{userRole[step - 1]?.subtitle}</span>
            <img src={userRole[step - 1]?.img} className={classes.img} alt={`slide-${step}`} />
          </div>
        )
      }
    };
    return (
      <div className={classes.waitingListWrapper}>
        <div className={classes.onboardingBody}>
          <div className={classes.onboardingSteps}>
            {formSteps()}
          </div>
          <div className={classes.onboardingStepsPagination}>
            <span className={`${classes.onboardingStepsPaginationSingle} 
              ${step === 1 && classes.onboardingStepsPaginationSingleActive}`
            }>
              <span className={classes.onboardingStepsPaginationSingleInner}/>
            </span>
            <span
              className={`${classes.onboardingStepsPaginationSingle} 
              ${step === 2 && classes.onboardingStepsPaginationSingleActive}`
            }>
              <span className={classes.onboardingStepsPaginationSingleInner}/>
            </span>
            <span
              className={`${classes.onboardingStepsPaginationSingle} 
              ${step === 3 && classes.onboardingStepsPaginationSingleActive}`
            }>
              <span className={classes.onboardingStepsPaginationSingleInner}/>
            </span>
            <span
              className={`${classes.onboardingStepsPaginationSingle}
              ${step === 4 && classes.onboardingStepsPaginationSingleActive}`
            }>
              <span className={classes.onboardingStepsPaginationSingleInner}/>
            </span>
            <span
              className={`${classes.onboardingStepsPaginationSingle} 
              ${step === 5 && classes.onboardingStepsPaginationSingleActive}`
            }>
              <span className={classes.onboardingStepsPaginationSingleInner} />
            </span>
          </div>
          <div className={classes.onboardingButtonsWrapper}>
            {this.state.step > 1 && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.onboardingBackButton}
                onClick={this.prevStep}
              >
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.onboardingButton}
              onClick={this.nextStep}
            >
              NEXT
            </Button>
          </div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.skipButton}
            component={Link}
            to="/parties-gallery"
          >
            SKIP
          </Button>
        </div>
      </div>
    );
  }
}

OnBoardingSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapActionsToProps = {};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withRouter(OnBoardingSlider)));
