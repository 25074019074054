import PageWrapper from '../../components/PageWrapper';
import { Stack, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDeleteNotificationMutation, useNotificationsQuery, useUpdateNotificationStatusMutation } from './api';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { transformDate } from '../../util/utils';
import { useSelector } from 'react-redux';
import NotificationInner from './NotificationItem';
import NoItemsBox from '../../components/NoItemsBox';

const Notifications = () => {
  const [updateStatus] = useUpdateNotificationStatusMutation();
  const {notifications} = useSelector(store => store);
  const {data, isLoading} = useNotificationsQuery(null, {refetchOnMountOrArgChange: notifications?.newNotificationsCount});

  useEffect(() => {
    if (data?.length > 0 && data?.some(item => !item.read) ) {
      updateStatus({body: {notifications: data.filter(item => !item.read).map(item => item?.id)}});
    }
  }, [data, updateStatus]);

  return (
    <PageWrapper>
      <Typography sx={{fontSize: 18, fontWeight: 400, mb: 3}}>Notifications</Typography>
      {data?.length === 0 && !isLoading && (
        <NoItemsBox
          title={'It\'s quiet for now..'}
          description={'Looks like the perfect moment to plan your next big event!'}
          buttonText={'Plan a New Party'}
        />
      )}
      <Stack direction={'column'} spacing={3} sx={{maxWidth: 600, width: '100%', mx: 'auto'}}>
        {data?.length > 0 && !isLoading && data?.map(item => (
          <NotificationItem data={item} key={item?.id} />
        ))}
      </Stack>
    </PageWrapper>
  );
};

export default Notifications;

const NotificationItem = ({data: {
  title,
  invitationId,
  applicationId,
  activeConversation,
  description,
  createdAt,
  userName,
  invitationName,
  _id
}}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteNotification] =  useDeleteNotificationMutation();
  const handleOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }

  const handleDelete = (id) => {
    deleteNotification(id).then(() => {
      setAnchorEl(null);
    });
  };

  return (
    <Box sx={{background: '#F9F5EF', p: 2}} >
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <NotificationInner
          title={title}
          description={description}
          invitationId={invitationId}
          applicationId={applicationId}
          activeConversation={activeConversation}
          userName={userName}
          invitationName={invitationName}
        />
        <IconButton onClick={handleOpen} disableRipple sx={{p: 0}}>
          <MoreVertIcon sx={{color: '#696969'}} />
        </IconButton>
        <Menu
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
          PaperProps={{
            elevation: 2,
            sx: {
              boxShadow: 'none'
            }
          }}
          MenuListProps={{
            sx: {
              p: 0,
              minWidth: anchorEl && anchorEl.offsetWidth,
            },
          }}

        >
          <MenuItem
            onClick={() => handleDelete(_id)}
            disableRipple
            sx={{textTransform: 'uppercase', fontSize: 12, boxShadow: 'none'}}
          >
            Delete
          </MenuItem>
        </Menu>
      </Stack>
      <Typography sx={{mt: 2, fontSize: 11, fontWeight: 600, color: '#8F8F8F'}}>{transformDate(createdAt)}</Typography>
    </Box>
  );
};
