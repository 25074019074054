import InstagramIcon from '@mui/icons-material/Instagram';
import { Button } from '../../components/UI/Button';
import React, { memo, useEffect, useMemo } from 'react';
import { useInstagramProfileMutation } from '../../services/mainApi';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const instagramData = {
  client_id: 1052732536069165,
  client_secret: '15c4ef3eb266c3aaddd81b20d304cf3b',
  // redirect_uri: window.location.href,
  // redirect_uri: `https://localhost:3000${window.location.pathname}`,
};

const ConnectInstagramButton = memo(({isConnected, onSuccess, onClick = () => {}}) => {
  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [instagramProfile, {isUninitialized, isLoading}] = useInstagramProfileMutation();

  useEffect(() => {
    if (urlParams.get('code') && isUninitialized) {
      instagramProfile({body: {
          client_id: instagramData.client_id,
          client_secret: instagramData.client_secret,
          grant_type: 'authorization_code',
          redirect_uri: `https://theparty.club${window.location.pathname}`,
          code: urlParams.get('code')
        }}).then(res => {
        if (res?.data?.username) {
          onSuccess(res?.data?.username);
          enqueueSnackbar(`Instagram account connected.`, {
            variant: 'info',
            autoHideDuration: 5000,
            preventDuplicate: true,
            key: 'not_found',
            action: key => <IconButton
              onClick={() => closeSnackbar(key)}
              color={'primary'}
            >
              <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
            </IconButton>
          });
        } else {
          enqueueSnackbar(`${res?.error?.data?.error}`, {
            variant: 'info',
            autoHideDuration: 5000,
            preventDuplicate: true,
            key: 'not_found',
            action: key => <IconButton
              onClick={() => closeSnackbar(key)}
              color={'primary'}
            >
              <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
            </IconButton>
          });
        }
      });
    }
  }, [urlParams, instagramProfile, onSuccess, isUninitialized]);

  const handleClick = () => {
    window.location.href= `https://api.instagram.com/oauth/authorize?client_id=${instagramData.client_id}&redirect_uri=https://theparty.club${window.location.pathname}&scope=user_profile,user_media&response_type=code`;
    onClick();
  };

  return (
    <>
      <Button
        fullWidth
        variant={'text'}
        startIcon={<InstagramIcon />}
        onClick={handleClick}
        sx={{height: 32, width: 'auto'}}
      >
        {!isConnected ? 'Connect' : 'Change'} instagram
      </Button>
      {isLoading && <Loader />}
    </>
  );
});

export default ConnectInstagramButton;
