import PageWrapper from '../PageWrapper';
import { Box, Stack, Typography } from '@mui/material';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import PartyImage from '../../assets/party-image.jpg';
import React, { useEffect } from 'react';
import { Button } from '../UI/Button';
import { useChangeEmailSubscriptionMutation } from '../../features/auth/api';
import { useLocation, useHistory } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const UnSubscribe = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const token = urlParams.get('token');
  const [changeSubscription] = useChangeEmailSubscriptionMutation();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      changeSubscription({isSubscribed: false, token: token});
    }
  }, []);//eslint-disable-line

  const onResubscribe = () => {
    changeSubscription({isSubscribed: true, token: token}).then(res => {
      if (res?.data) {
        history.push('/parties-gallery');
        enqueueSnackbar(`You have been subscribed successfully`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  return (
    <PageWrapper>
      <Stack direction={'row'} alignItems={'center'} sx={{borderRadius: '20px', overflow: 'hidden', border: '1px solid #ccc', width: '100%'}}>
        <Box sx={{
          py: 2,
          pl: {xs: 2, sm: 3},
          pr: {xs: 2, sm: 5},
          display: 'flex',
          flexDirection: 'column',
          minWidth: {xs: '100%', sm: 360}
        }}>
          <UnsubscribeIcon sx={{fontSize: 40, color: '#0E1D31'}}/>
          <Typography sx={{fontSize: 18, fontWeight: 600, mt: 2}}>You have been unsubscribed successfully</Typography>
          <Typography sx={{maxWidth: {xs: '100%', sm: 300}, width: '100%', fontSize: 14, mt: 1}}>
            Thank you for being part of our community. If you change your mind just click the button below to
            resubscribe and stay connected with all that's happening.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onResubscribe}
            sx={{maxWidth: '280px', mt: 2, width: '100%'}}
          >
            Resubscribe
          </Button>
        </Box>
        <Box sx={{
          flexGrow: 1,
          height: 300,
          display: {xs: 'none', sm: 'flex'},
          '& img': {width: '100%', height: '100%', objectFit: 'cover'}
        }}>
          <img src={PartyImage} alt="party"/>
        </Box>
      </Stack>
    </PageWrapper>
  )
};

export default UnSubscribe;
