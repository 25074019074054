import { useApplicationQuery, useInvitationApplicationsQuery, useUpdateApplicationStatusMutation } from './api';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { Box, Dialog, DialogContent, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import ApplicationFilters from './ApplicationFilters';
import ScrollableSidebar from '../../components/ScrollableSidebar';
import SidebarItem from '../../components/SidebarItem';
import { theme } from '../../services/Theme';
import { transformDate } from '../../util/utils';
import Application from './application';
import { Button } from '../../components/UI/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ModalTitle from '../../components/UI/DialogTitle';
import Loader from '../../components/Loader';
import NoItemsBox from '../../components/NoItemsBox';
import useFullPageHeight from '../../util/useFullPageHeight';

const InvitationApplications = () => {
  const {id} = useParams();
  const [params, setParams] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);
  const [openApplicationModal, setOpenApplicationModal] = useState(false);
  const {data: applications, isLoading: isLoadingApplications} = useInvitationApplicationsQuery({id, params});
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const height = useFullPageHeight();

  useEffect(() => {
    if (applications && !selectedApp) {
      setSelectedApp(applications[0]);
    }
  }, [applications]);  //eslint-disable-line

  const onFilter = (name, value) => {
    setSelectedApp(null);
    if (!value.length) {
      const {[name]: filter, ...restFilters} = params;
      setParams(restFilters);
      return;
    }
    setParams(state => ({...state, [name]: value}));
  }

  const onApplicationClick = (application) => () => {
    if (isMobile) {
      setOpenApplicationModal(true);
    }
    setSelectedApp(application);
  }

 return (
   <PageWrapper sx={{ overflowY: 'hidden', height: `calc(${height} - 45px)`}}>
     <Stack direction={'row'} sx={{height: '100%'}}>
       <Box sx={{
         width: {xs: '100%', md: applications?.length === 0 || !applications ? '100%' : '30%'},
         height: '100%',
         display: 'flex',
         flexDirection: 'column',
         position: 'relative',
         '&:after': {
           content: '""',
           position: 'absolute',
           top: -30,
           right: 0,
           width: '1px',
           height: 'calc(100% + 60px)',
           backgroundColor: applications?.length > 0 && !isLoadingApplications ? '#0E1D31' : 'transparent',
           display: {xs: 'none', md: 'block'},
         }
       }}
       >
         <Typography sx={{mb: 3, fontWeight: 500}}>
           Applications
         </Typography>
         {(applications?.length > 0) && (
           <ApplicationFilters
             sx={{pr: {xs: 0, sm: 3.5}, mb: 3}}
             hideCancelled
             itemsCount={applications?.length}
             handleClear={() => setParams({})}
             handleFilter={onFilter}
           />
         )}
         <ScrollableSidebar>
           {applications?.map((item) => (
             <SidebarItem
               key={item?.id}
               onClick={onApplicationClick(item)}
               selected={selectedApp?.id === item?.id}
               image={item?.user?.imgUrl[item?.user?.imgUrl.length - 1]}
             >
               <Stack direction={'column'} spacing={0.5} sx={{overflow: 'hidden'}}>
                 <Typography sx={{fontSize: 15, fontWeight: 600}}>{item?.user?.username}</Typography>
                 <Typography
                   sx={{
                     fontSize: 14,
                     fontWeight: 500,
                     display: '-webkit-box',
                     WebkitLineClamp: '1',
                     WebkitBoxOrient: 'vertical',
                     overflow: 'hidden'
                   }}
                 >
                   {item?.messageToHost}
                 </Typography>
                 <Stack direction={'row'} spacing={2}>
                  <Typography sx={{fontSize: 11, fontWeight: 600, textTransform: 'uppercase'}}>{item?.status}</Typography>
                  <Typography sx={{fontSize: 11, fontWeight: 600, color: '#8F8F8F'}}>{transformDate(item?.updatedAt)}</Typography>
                 </Stack>
               </Stack>
             </SidebarItem>
           ))}
           {(!applications || applications?.length === 0) && !isLoadingApplications && (
             <NoItemsBox
               title={'No applications pending.'}
               description={'Seize the opportunity to join new parties and experiences!'}
               buttonText={'Explore Parties'}
               buttonLink={'/parties-gallery'}
             />
           )}
         </ScrollableSidebar>
       </Box>
       <Box
         sx={{
           display: {xs: 'none', md: (applications?.length > 0)  ? 'block' : 'none'},
           width: '70%',
           position: 'relative',
           height: '100%',
           pl: 3,
           overflowY:'auto'
         }}
       >
         {applications?.length > 0 && !isLoadingApplications && <Details selectedAppId={selectedApp?.id} />}
       </Box>
     </Stack>
     <Dialog
       fullScreen
       open={openApplicationModal && isMobile}
       onClose={() => setOpenApplicationModal(false)}
       sx={{
         '& .MuiPaper-root': {
           maxWidth: 1300,
           width: '100%',
         },
         "& .MuiBackdrop-root": {
           backgroundColor: "#b0b0b04d",
         },
       }}
     >
       <ModalTitle onClose={() => setOpenApplicationModal(false)} />
       <DialogContent sx={{p: 3, mt: 3}}>
         <Details selectedAppId={selectedApp?.id} />
       </DialogContent>
     </Dialog>
   </PageWrapper>
 );
};

export default InvitationApplications;

export const Details = ({selectedAppId, twoColumns}) => {
  const {data: application} = useApplicationQuery(selectedAppId, {skip: !selectedAppId});
  const isAccepted = application?.status === 'accepted';
  const isWaitlisted = application?.status === 'waitlisted';
  const isRejected = application?.status === 'rejected';
  const isCancelled = application?.status === 'cancelled';
  const [updateStatus, {isLoading}] = useUpdateApplicationStatusMutation();
  const [open, setOpen] = useState(false);
  const [isWaitlistDialog, setIsWaitlistDialog] = useState(false);

  const onChangeStatus = (value) => () => {
    if (value === application?.status) {
      return;
    }
    updateStatus({id: application?.id, body: {status: value}}).then(res => {
      if (res.data && open) {
        setOpen(false);
        if (isWaitlistDialog) {
          setIsWaitlistDialog(false);
        }
      }
    });
  };

  return (
    <>
      {application && !isLoading && (
        <>
          <Grid container spacing={1} alignItems={'center'} sx={{mb: 6}}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{fontSize: 17, color: '#0E1D31', fontWeight: 'bold', textAlign: 'left', textTransform: 'capitalize'}}>
                {application?.user?.username}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{fontSize: 17, color: '#373632', fontWeight: 'bold', textAlign: {xs: 'left', sm: 'right'}}}>
                {application?.invitation?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{fontSize: 13, color: '#373632', textAlign: 'left', textTransform: 'uppercase', '& span': {fontWeight: 600}}}>
                application status: <span>{application?.status}</span>
              </Typography>
            </Grid>
          </Grid>
          <Application application={{...application, ...application?.user}} isTwoColumnsLayout={twoColumns} />
          {!isCancelled && (
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={{xs: 1, sm: 2}} sx={{mt: 3}}>
              <Button
                variant={'outlined'}
                fullWidth
                sx={{
                  '&.MuiButton-outlined': {
                    height: 48,
                    background: isRejected ? '#C3352B' : 'transparent',
                    border: `2px solid ${isRejected ? '#C3352B' : '#F44336'}`,
                    color: `${isRejected ? '#fff' : '#F44336'} !important`,
                    boxShadow: 'none',
                    fontSize: {xs: 10, sm: 14},
                    px: {xs: 1, sm: 2},
                    '&:hover': {
                      background: isRejected ? '#C3352B' : 'transparent',
                    }
                  }
                }}
                startIcon={<CloseIcon sx={{color: isRejected ? '#fff' : '#F44336', fontSize: {xs: '12px !important', sm: '20px !important'}}} />}
                onClick={onChangeStatus('rejected')}
                disabled={isLoading}
              >
                {isRejected ? 'Rejected' : 'Reject'}
              </Button>
              <Button
                variant={'outlined'}
                fullWidth
                sx={{
                  '&.MuiButton-outlined': {
                    height: 48,
                    background: isWaitlisted ? '#8F8F8F' : 'transparent',
                    border: `2px solid ${isWaitlisted ? '#8F8F8F' : '#B8B7B7'}`,
                    color: `${isWaitlisted ? '#fff' : '#8F8F8F'} !important`,
                    boxShadow: 'none',
                    fontSize: {xs: 10, sm: 14},
                    px: {xs: 1, sm: 2},
                    '&:hover': {
                      background: isWaitlisted ? '#8F8F8F' : 'transparent'
                    }
                  }
                }}
                startIcon={<HourglassEmptyIcon sx={{color: isWaitlisted ? '#fff' : '#8F8F8F', fontSize: {xs: '12px !important', sm: '20px !important'}}} />}
                onClick={onChangeStatus('waitlisted')}
                disabled={isLoading}
              >
                {isWaitlisted ? 'WAITLISTED' : 'WAITLIST'}
              </Button>
              <Button
                variant={'outlined'}
                color={'success'}
                fullWidth
                sx={{
                  '&.MuiButton-outlinedSuccess': {
                    height: 48,
                    background: isAccepted ? '#589C0C' : 'transparent',
                    border: `2px solid #589C0C`,
                    color: `${isAccepted ? '#fff' : '#589C0C'} !important`,
                    boxShadow: 'none',
                    fontSize: {xs: 10, sm: 14},
                    px: {xs: 1, sm: 2},
                  }
                }}
                startIcon={<CheckIcon sx={{color: isAccepted ? '#fff' : '#589C0C', fontSize: {xs: '12px !important', sm: '20px !important'}}} />}
                onClick={onChangeStatus('accepted')}
                disabled={isLoading}
              >
                {isAccepted ? 'ACCEPTED' : 'ACCEPT'}
              </Button>
            </Stack>
          )}
        </>
      )}
      {!selectedAppId && (<Typography>Please select application</Typography>)}
      {!application && !isLoading && <Typography>Application was not found</Typography>}
      {isLoading && <Loader />}
    </>
  )
};
